import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export function Loader(props: { noShow?: boolean }) {
    if (!props.noShow)
        return <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <CircularProgress color={'inherit'}/></div>
    return <div/>
}
