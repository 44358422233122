import { CircularProgress } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Formulario } from 'components/Formulario';
import { Loader } from 'components/Loader';
import { Cliente } from 'models/Cliente';
import { Empreendimento } from 'models/Empreemdimento';
import { UnidadeVendida } from 'models/UnidadeVendida';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardTitle,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { ApiService } from 'services/ApiService';

export function ClientePage(props: any) {
    const [cliente, setCliente] = useState<Cliente | null>();
    const [loading, setLoading] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [showFormUnidade, setShowFormUnidade] = useState(false);
    const [unidadeForm, setUnidadeForm] = useState<UnidadeVendida>();
    const [activeTab, setActiveTab] = useState('1');
    useEffect(() => {
        if (cliente === undefined) {
            (async () => {
                try {
                    setCliente(
                        new Cliente(
                            await ApiService.get(
                                '/clientes',
                                props.match.params.id
                            )
                        )
                    );
                } catch {
                    setCliente(null);
                }
            })();
        }
    });

    const handleSubmitEdicao = (
        data: any,
        callbackErro?: any,
        form?: HTMLFormElement | null
    ) => {
        setLoading(true);
        if (cliente) {
            const cpf = String(data.cpf).replace(/[^0-9]/g, '');
            ApiService.put('/clientes', String(cliente.id), {
                ...cliente,
                ...data,
                cpf: cpf,
            }).then(
                (clienteEditado) => {
                    setCliente(new Cliente(clienteEditado));
                    setLoading(false);
                },
                (err) => {
                    setSaveSuccess(false);
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                }
            );
        }
    };

    const handleSubmitUnidade = (data: any, callbackErro?: any) => {
        setLoading(true);
        if (!unidadeForm?.id) {
            ApiService.post('/unidades-clientes', {
                ...data,
                cliente: cliente?.id,
            }).then(
                (u) => {
                    setCliente(undefined);
                    setLoading(false);
                    setSaveSuccess(true);
                    setShowFormUnidade(false);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                    setSaveSuccess(false);
                }
            );
        } else {
            ApiService.put('/unidades-clientes', String(unidadeForm.id), {
                ...data,
                cliente: cliente?.id,
            }).then(
                (t) => {
                    setCliente(undefined);
                    setLoading(false);
                    setSaveSuccess(true);
                    setShowFormUnidade(false);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                    setSaveSuccess(false);
                }
            );
        }
    };
    return (
        <div className="app-wrapper">
            <Card>
                {cliente === undefined && (
                    <CardBody>
                        <Loader />
                    </CardBody>
                )}
                {cliente && (
                    <CardBody>
                        <CardTitle>
                            <button
                                className="btn btn-link text-dark"
                                onClick={() => props.history.goBack()}
                            >
                                <ArrowBack fontSize="large" />
                            </button>
                            {cliente.nome}
                        </CardTitle>
                        <hr />
                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={
                                            activeTab === '1' ? 'active' : ''
                                        }
                                        onClick={() => {
                                            setActiveTab('1');
                                            setSaveSuccess(false);
                                        }}
                                    >
                                        Informações Cadastrais
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            activeTab === '2' ? 'active' : ''
                                        }
                                        onClick={() => {
                                            setActiveTab('2');
                                            setSaveSuccess(false);
                                        }}
                                    >
                                        Unidades Adquiridas
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Card>
                                        <CardBody>
                                            {saveSuccess &&
                                                activeTab === '1' && (
                                                    <Alert color="success">
                                                        Dados atualizado com
                                                        sucesso!
                                                    </Alert>
                                                )}
                                            <Formulario
                                                name="Perfil"
                                                fields={cliente.getFieldsFormulario()}
                                                loading={loading}
                                                onSubmit={handleSubmitEdicao}
                                                textSubmitButton="Salvar"
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Card>
                                        <CardBody>
                                            {saveSuccess &&
                                                activeTab === '2' && (
                                                    <Alert color="success">
                                                        Dados atualizado com
                                                        sucesso!
                                                    </Alert>
                                                )}
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setUnidadeForm(
                                                        new UnidadeVendida()
                                                    );
                                                    setShowFormUnidade(true);
                                                }}
                                            >
                                                Adicionar Nova Unidade
                                            </button>
                                            <button
                                                className="btn btn-info"
                                                onClick={() => {
                                                    handleSubmitEdicao({
                                                        update_vendas: true,
                                                        cpf: cliente.cpf,
                                                    });
                                                }}
                                                disabled={loading}
                                            >
                                                {loading && (
                                                    <CircularProgress
                                                        color={'inherit'}
                                                        size={'1rem'}
                                                    />
                                                )}
                                                Atualizar Unidades Adquiridas
                                            </button>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Empreendimento
                                                            </th>
                                                            <th>Quadra/Lote</th>
                                                            <th>
                                                                Descrição UAL
                                                            </th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cliente ===
                                                            undefined && (
                                                            <tr>
                                                                <td colSpan={3}>
                                                                    <Loader />
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {cliente &&
                                                            cliente.unidades &&
                                                            cliente.unidades.map(
                                                                (u) => {
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                u.id
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {
                                                                                    u.empreendimento_nome
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                Quadra:{' '}
                                                                                {
                                                                                    u.quadra
                                                                                }{' '}
                                                                                -
                                                                                Lote:{' '}
                                                                                {
                                                                                    u.lote
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    u.descricao
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => {
                                                                                        setUnidadeForm(
                                                                                            u
                                                                                        );
                                                                                        setShowFormUnidade(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Editar
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-danger"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            window.confirm(
                                                                                                'Deseja relamente remover essa unidade?'
                                                                                            )
                                                                                        ) {
                                                                                            ApiService.delete(
                                                                                                '/unidades-clientes',
                                                                                                String(
                                                                                                    u.id
                                                                                                )
                                                                                            ).then(
                                                                                                () => {
                                                                                                    setCliente(
                                                                                                        undefined
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    Remover
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        {cliente &&
                                                            cliente.unidades
                                                                ?.length ===
                                                                0 && (
                                                                <tr>
                                                                    <td
                                                                        colSpan={
                                                                            5
                                                                        }
                                                                    >
                                                                        Nenhuma
                                                                        unidade
                                                                        cadastrada
                                                                    </td>
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </div>
                    </CardBody>
                )}
            </Card>
            <Modal
                isOpen={showFormUnidade}
                toggle={() => setShowFormUnidade((s) => !s)}
            >
                <ModalHeader toggle={() => setShowFormUnidade((s) => !s)}>
                    Cadastro de Unidade Adquirida
                </ModalHeader>
                <ModalBody>
                    <Formulario
                        name="UnidadeAdquirida"
                        fields={[
                            {
                                type: 'select',
                                name: 'empreendimento',
                                label: 'Empreendimento',
                                valueDefault: unidadeForm
                                    ? {
                                          value: unidadeForm.empreendimento,
                                          label: unidadeForm.empreendimento_nome,
                                      }
                                    : undefined,
                                choices: () => {
                                    return ApiService.getAll(
                                        '/empreendimentos',
                                        { paginate: 'none' }
                                    ).then((e: Empreendimento[]) => {
                                        return e.map((x) => {
                                            return {
                                                label: String(x.nome),
                                                value: String(x.id),
                                            };
                                        });
                                    });
                                },
                            },
                            {
                                label: 'Quadra',
                                name: 'quadra',
                                type: 'text',
                                valueDefault: unidadeForm?.quadra,
                                validators: {
                                    required: {
                                        value: true,
                                        message: 'Informe a quadra',
                                    },
                                },
                            },
                            {
                                label: 'Lote',
                                name: 'lote',
                                valueDefault: unidadeForm?.lote,
                                type: 'number',
                                validators: {
                                    required: {
                                        value: true,
                                        message: 'Informe o Lote',
                                    },
                                },
                            },
                            {
                                label: 'Descrição UAL',
                                name: 'descricao',
                                valueDefault: unidadeForm?.descricao,
                                type: 'text',
                            },
                        ]}
                        loading={loading}
                        onSubmit={handleSubmitUnidade}
                        textSubmitButton="Salvar"
                    />
                </ModalBody>
            </Modal>
        </div>
    );
}
