import { IJsonFromField } from 'components/InputFormulario/types';
import { Empreendimento } from 'models/Empreemdimento';
import { ApiService } from 'services/ApiService';

export class Noticia {
    public id?: number;
    public titulo?: string;
    public texto?: string;
    public foto?: string;
    public data_cadastro?: string;
    public ativa?: boolean
    public empreendimento_detalhe?: Empreendimento;


    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            })
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                type: 'select',
                name: 'empreendimento',
                label: 'Empreendimento',
                valueDefault: this.empreendimento_detalhe ? {
                    value: this.empreendimento_detalhe.id,
                    label: this.empreendimento_detalhe.nome
                } : undefined,
                choices: () => {
                    return ApiService.getAll('/empreendimentos', { paginate: 'none' }).then((e: Empreendimento[]) => {
                        return e.map(x => {
                            return {
                                label: String(x.nome),
                                value: x.id
                            }
                        })
                    })
                },
                validators: {
                    required: {
                        value: true,
                        message: 'Empreendimento é obrigatório'
                    }
                }
            },
            {
                name: 'titulo',
                label: 'Nome',
                type: 'text',
                valueDefault: this.titulo,
                validators: {
                    required: {
                        value: true,
                        message: 'Título é obrigatório'
                    }
                }
            },
            {
                name: 'texto',
                label: 'Texto',
                type: 'text-area',
                valueDefault: this.texto,
                validators: {
                    required: {
                        value: true,
                        message: 'Texto é obrigatório'
                    }
                }
            },
            {
                name: 'ativa',
                label: 'Ativa',
                type: 'select',
                valueDefault: this.ativa !== undefined ? {
                    value: String(this.ativa),
                    label: this.ativa ? 'Sim' : 'Não'
                } : undefined,
                choices: [
                    {
                        label: 'Sim',
                        value: 'true'
                    },
                    {
                        label: 'Não',
                        value: 'false'
                    },
                ],
                validators: {
                    required: {
                        value: true,
                        message: 'Ativa é obrigatório'
                    }
                }
            }
        ];
    }
}
