import {IJsonFromField} from 'components/InputFormulario/types';
import {ApiService} from 'services/ApiService';
import {Estado} from 'models/Estado';
import {ANDAMENTO_EMPREENDIMENTO} from 'models/Empreemdimento';
import {UnidadeVendida} from 'models/UnidadeVendida';

export const STATUS_CLIENTE: { [key: string]: string } = {
    '0': 'Aguardando Ativação',
    '1': 'Ativo',
    '2': 'Inativo',
};

export class UsuarioCliente {
    public id?: number;
    public first_name?: string;
    public last_name?: string;
    public cliente?: Cliente;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            })
        }
    }
}

export class Cliente {
    public id?: number
    public nome?: string
    public cpf?: string
    public email?: string
    public celular?: string
    public telefone?: string
    public endereco?: string
    public cidade?: number
    public numero_comp?: string
    public cep?: string
    public unidades?: UnidadeVendida[]
    public cidade_nome?: string
    public estado?: number
    public estado_sigla?: string
    public status?: number
    public data_cadastro?: string

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            })
        }
    }

    public getCpfFormatado() {
        const cpf = this.cpf?.replace(/^\D+/g, '')
        if (cpf) {
            const [p1, p2, p3, p4] = [
                cpf.substr(0, 3),
                cpf.substr(3, 3),
                cpf.substr(6, 3),
                cpf.substr(9, 2)
            ];
            return `${p1}.${p2}.${p3}-${p4}`
        }
        return cpf
    }

    public getStatusDisplay() {
        return STATUS_CLIENTE[String(this.status)]
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'nome',
                label: 'Nome',
                type: 'text',
                valueDefault: this.nome,
                validators: {
                    required: {
                        value: true,
                        message: 'Nome é obrigatório'
                    }
                }
            },
            {
                name: 'email',
                label: 'Email',
                type: 'email',
                valueDefault: this.email,
                validators: {
                    required: {
                        value: true,
                        message: 'Email é obrigatório'
                    }
                }
            },
            {
                name: 'cpf',
                label: 'CPF',
                type: 'cpf',
                valueDefault: this.cpf,
                validators: {
                    required: {
                        value: true,
                        message: 'CPF é obrigatório'
                    }
                }
            },
            {
                name: 'endereco',
                label: 'Endreço',
                type: 'text',
                valueDefault: this.endereco,
            },
            {
                name: 'numero_comp',
                label: 'Número/Complemento',
                type: 'text',
                valueDefault: this.numero_comp,
            },
            {
                name: 'estado',
                label: 'Estado',
                type: 'select',
                valueDefault: this.estado ? {value: this.estado, label: this.estado_sigla} : undefined,
                onChange: data => this.estado = data,
                choices: () => {
                    return ApiService.getAll('/estados').then(data => {
                        return data.map((e: Estado) => {
                            return {
                                label: e.sigla,
                                value: e.id
                            }
                        })
                    })
                }
            },
            {
                name: 'cidade',
                label: 'Cidade',
                type: 'select',
                valueDefault: this.cidade ? {value: this.cidade, label: this.cidade_nome} : undefined,
                onChange: data => this.cidade = data,
                choices: () => {
                    return ApiService.getAll('/cidades', {estado_id: this.estado}).then(data => {
                        return data.map((e: Estado) => {
                            return {
                                label: e.nome,
                                value: e.id
                            }
                        })
                    })
                }
            },
            {
                name: 'cep',
                label: 'CEP',
                type: 'text',
                valueDefault: this.cep,
                validators: {
                    maxLength: {
                        value: 8,
                        message: 'Informe somente 8 caracteres'
                    }
                }
            },
            {
                name: 'celular',
                label: 'Celular',
                type: 'tel',
                valueDefault: this.celular,
            },
            {
                name: 'telefone',
                label: 'Telefone',
                type: 'tel',
                valueDefault: this.telefone,
            },
            {
                name: 'status',
                label: 'Status',
                type: 'select',
                valueDefault: this.status !== undefined ? {
                    value: String(this.status),
                    label: STATUS_CLIENTE[String(this.status)]
                } : undefined,
                validators: {
                    required: {
                        value: true,
                        message: 'Status é obrigatório'
                    }
                },
                choices: Object.keys(STATUS_CLIENTE).map(x => {
                    return {
                        value: String(x),
                        label: STATUS_CLIENTE[x]
                    }
                })
            }
        ];
    }
}
