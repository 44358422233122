import React, { useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardFooter, CardTitle, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Loader } from 'components/Loader';
import { ControleDePaginacao } from 'components/ControleDePaginacao';
import { Paginacao } from 'util/Paginacao';
import { Empreendimento } from 'models/Empreemdimento';
import { ApiService } from 'services/ApiService';
import { RouteComponentProps } from 'react-router';
import { Formulario } from 'components/Formulario';
import EditIcon from '@material-ui/icons/Edit';

export function EmpreendimentosPage(props: RouteComponentProps) {
    const [paginacaoEmpreemdimentos, setPaginacaoEmpreendimentos] = useState<Paginacao<Empreendimento> | null>();
    const [empreendimentos, setEmpreendimentos] = useState<Empreendimento[]>();
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (paginacaoEmpreemdimentos === undefined) {
            (async () => {
                try {
                    const pg: Paginacao<Empreendimento> = new Paginacao<Empreendimento>(
                        '/empreendimentos',
                        Empreendimento,
                        await ApiService.getAll('/empreendimentos')
                    );
                    setPaginacaoEmpreendimentos(pg);
                    setEmpreendimentos(await pg.getItensProximaPagina());
                } catch {
                    setEmpreendimentos([]);
                    setPaginacaoEmpreendimentos(null);
                }
            })();
        }
    });
    const handleSubmit = async (data: any, callbackErro?: any) => {
        setLoading(true);
        ApiService.post('/empreendimentos', data).then(
            (emp) => {
                setEmpreendimentos((emps) => {
                    return emps ? [new Empreendimento(emp), ...emps] : [new Empreendimento(emp)];
                });
                setLoading(false);
                setShowForm(false);
            },
            (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data);
                }
                setLoading(false);
            }
        );
    };
    return (
        <div className="app-wrapper">
            <Card>
                <CardBody>
                    <CardTitle>Empreendimentos</CardTitle>
                    <hr />
                    <div>
                        <p>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setShowForm(true);
                                }}>
                                Novo Empreendimento
                            </button>
                        </p>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Cidade</th>
                                        <th>Andamento</th>
                                        <th>Ativo</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {empreendimentos === undefined && (
                                        <tr>
                                            <td colSpan={5}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    )}
                                    {empreendimentos &&
                                        empreendimentos.map((u) => {
                                            return (
                                                <tr key={u.id}>
                                                    <td>{u.nome}</td>
                                                    <td>
                                                        {u.cidade_nome} - {u.estado_sigla}
                                                    </td>
                                                    <td>{u.getAndamentoDisplay()}</td>
                                                    <td>
                                                        {u.ativo ? (
                                                            <Badge color="success">Sim</Badge>
                                                        ) : (
                                                            <Badge color="danger">Não</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={() => {
                                                                props.history.push('/portal/empreendimento/' + u.id);
                                                            }}>
                                                            <EditIcon fontSize={'small'} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {empreendimentos && empreendimentos.length === 0 && (
                                        <tr>
                                            <td colSpan={5}>Nenhum empreendimento cadastrado</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    {paginacaoEmpreemdimentos && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoEmpreemdimentos.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setEmpreendimentos(undefined);
                                paginacaoEmpreemdimentos.getItensDaPagina(pg).then((us) => {
                                    setEmpreendimentos(us);
                                });
                            }}
                        />
                    )}
                </CardFooter>
            </Card>
            <Modal isOpen={showForm} toggle={() => setShowForm((s) => !s)}>
                <ModalHeader toggle={() => setShowForm((s) => !s)}>Novo Empreendimento</ModalHeader>
                <ModalBody>
                    <Formulario
                        name="Perfil"
                        fields={new Empreendimento().getFieldsFormulario()}
                        loading={loading}
                        onSubmit={handleSubmit}
                        textSubmitButton="Salvar"
                    />
                </ModalBody>
            </Modal>
        </div>
    );
}
