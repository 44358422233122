import {ApiEvent} from 'app-events';
import * as queryString from 'query-string';

export enum ApiEventsType {
    HTTP_ERROR = 'httpError',
    FETCH_ERROR = 'fetchError',
}

export class ApiService {

    public static BASE_URL: string = '/api/portal';

    private static getCookie(name: string) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    private static checkFetchResponse = (response: Promise<Response>) => {
        return response.then((response) => {
            if (response.status >= 200 && response.status <= 399) {
                if (response.status === 204) {
                    return {};
                }
                return response.json();
            } else {
                ApiEvent.emit(ApiEventsType.HTTP_ERROR, {
                    status: response.status,
                    error: response.statusText
                });
                return new Promise((res, rej) => {
                    response.json().then(data => {
                        rej({
                            status: response.status,
                            data: data,
                            error: response.statusText
                        })
                    }, e => {
                        rej({
                            status: 0,
                            error: e
                        });
                    })
                })
            }
        }, (e) => {
            ApiEvent.emit(ApiEventsType.FETCH_ERROR, e);
            return {
                error: e,
                status: 0
            }
        })
    };

    private static compileUrl(partialUrl: string, id?: string, params?: { [name: string]: any } | queryString.ParsedQuery) {
        let url = ApiService.BASE_URL + partialUrl;
        if (id) {
            if (url.endsWith('/')) {
                url += id;
            } else {
                url += '/' + id;
            }
        }
        url = url.endsWith('/') ? url : url + '/';
        if (params) {
            url += '?' + queryString.stringify(params);
        }
        return url
    }


    public static get(partialUrl: string, id?: string, params?: { [name: string]: any } | queryString.ParsedQuery): Promise<any> {
        const url = ApiService.compileUrl(partialUrl, id, params);
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));
    }

    public static getAll(partialUrl: string, params?: { [name: string]: any } | queryString.ParsedQuery): Promise<any> {
        const url = ApiService.compileUrl(partialUrl, undefined, params);
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));
    }

    public static post(partialUrl: string, data: any): Promise<any> {
        const url = ApiService.compileUrl(partialUrl);
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'POST',
            headers: data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

    public static put(partialUrl: string, id: string, data: any): Promise<any> {
        const url = ApiService.compileUrl(partialUrl, id);
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'PUT',
            headers:  data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

    public static patch(partialUrl: string, id: string, data: any): Promise<any> {
        const url = ApiService.compileUrl(partialUrl, id);
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'PATCH',
            headers:  data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

    public static delete(partialUrl: string, id: string, data?: any) {
        const url = ApiService.compileUrl(partialUrl, id);
        return ApiService.checkFetchResponse(fetch(url.toString(), {
            method: 'DELETE',
            headers:  data instanceof FormData ? {
                'enctype': data instanceof FormData ? 'multipart/form-data' : 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': ApiService.getCookie('csrftoken') || ''
            },
            body: data instanceof FormData ? data : JSON.stringify(data)
        }));
    }

}
