import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from '../../components/Navigation';
import {useSelector} from 'react-redux';
import {AppRootState} from 'reducers';
import {PERMISSOES_USUARIO, Usuario} from 'models/Usuario';

export function SideBarContent() {
    const usuario = useSelector<AppRootState, Usuario | undefined>(({session}) => session.usuario);
    const navigationMenus = []
    if (usuario) {
        const permissoes = usuario.superusuario ? Object.keys(PERMISSOES_USUARIO) : usuario.permissoes;
        permissoes?.forEach((x:any) => {
            switch (Number(x)) {
                case 0:
                    navigationMenus.push({
                        name: 'Usuários',
                        ordem: 40,
                        type: 'item',
                        link: '/portal/usuarios'
                    });
                    break;
                case 1:
                    navigationMenus.push({
                        name: 'Empreendimentos',
                        ordem: 30,
                        type: 'item',
                        link: '/portal/empreendimentos'
                    });
                    break;
                case 2:
                    navigationMenus.push({
                        name: 'Clientes',
                        ordem: 20,
                        type: 'item',
                        link: '/portal/clientes'
                    });
                    break;
                case 3:
                    navigationMenus.push({
                        name: 'Notícias',
                        ordem: 10,
                        type: 'item',
                        link: '/portal/noticias'
                    });
                    break;
                case 4:
                    navigationMenus.push({
                        name: 'Prestadores de Serviço',
                        ordem: 0,
                        type: 'item',
                        link: '/portal/prestadores'
                    });
                    break;
                case 5:
                    navigationMenus.push({
                        name: 'Reservas',
                        ordem: 5,
                        type: 'item',
                        link: '/portal/reservas'
                    });
                    break;
            }
        })
        navigationMenus.push({
            name: 'Perfil',
            type: 'item',
            ordem: 400,
            link: '/portal/perfil'
        });

    }
    return (
        <CustomScrollbars className="scrollbar">
            <Navigation menuItems={navigationMenus.sort((x,y) => x.ordem >= y.ordem ? 1 : -1)}/>
        </CustomScrollbars>
    );
}
