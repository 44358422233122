import CircularProgress from '@material-ui/core/CircularProgress';
import { FieldJsonForm } from 'components/InputFormulario';
import React, { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { IJsonFromProps } from './types';

const defaultProps: IJsonFromProps = {
    onSubmit: () => {},
    textSubmitButton: 'Submit',
    name: '',
    fields: []
};

export function Formulario(props: PropsWithChildren<IJsonFromProps>) {
    const localProps: IJsonFromProps = Object.assign(defaultProps, props);
    const formMethods = useForm({
        reValidateMode: 'onSubmit',
        defaultValues: localProps.fields.reduce((previousValue, currentValue) => {
            previousValue[currentValue.name] = Array.isArray(currentValue.valueDefault)
                ? currentValue.valueDefault.map((x) => (x?.value !== undefined ? x.value : x))
                : currentValue.valueDefault?.value !== undefined
                ? currentValue.valueDefault?.value
                : currentValue.valueDefault;
            return previousValue;
        }, {} as any)
    });

    localProps.fields.forEach((x) => {
        if (x.onChange) x.onChange(formMethods.watch(x.name), formMethods.setValue);
    });

    let formElement: HTMLFormElement | null;

    const handleSubmit = (e: any) => {
        formMethods.clearErrors();
        return formMethods.handleSubmit((data: any) => {
            localProps.onSubmit(
                data,
                (erros) => {
                    if (erros) {
                        Object.keys(erros).forEach((key) => {
                            const erro: any = erros[key] || '';
                            if (Array.isArray(erro)) {
                                formMethods.setError(key, {
                                    types: erro.reduce((pre, pos, i) => {
                                        pre[`erro_${i}`] = pos;
                                        return pre;
                                    }, {} as any)
                                });
                            } else {
                                formMethods.setError(key, {
                                    message: erro as string,
                                    type: 'required'
                                });
                            }
                        });
                    }
                },
                formElement
            );
        })(e);
    };

    return (
        <form
            autoComplete="off"
            name={localProps.name}
            ref={(el) => {
                formElement = el;
            }}
            onSubmit={handleSubmit}>
            <fieldset>
                {props.fields.map((field) => {
                    return <FieldJsonForm formMethods={formMethods} key={field.name} field={field} />;
                })}
            </fieldset>
            <div className="row mt-1">
                <div className="col-6">{props.children}</div>
                <div className="col-6">
                    <p className="text-right">
                        <button
                            className={`btn btn-${props.submitButtonVariant || 'primary'}`}
                            type="submit"
                            disabled={props.loading}>
                            {props.loading && <CircularProgress color={'inherit'} size={'1rem'} />}
                            {!props.loading && localProps.textSubmitButton}
                        </button>
                    </p>
                </div>
            </div>
        </form>
    );
}
