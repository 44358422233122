import React, { useEffect, useState } from 'react';
import { IJsonFromField } from 'components/InputFormulario/types';
import { FormHelperText } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { UseFormMethods } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import InputMask from 'react-input-mask';
import Select2, { OptionType } from 'components/Select2';
import { LocalizacaoInput } from 'components/LocalizacaoInputField';
import NumberFormat from 'react-number-format';

export function FieldJsonForm(props: { field: IJsonFromField; formMethods: UseFormMethods }) {
    const [valueSelect, setValueSelect] = useState<OptionType[] | OptionType | null>(props.field.valueDefault || null);
    const [inputRegistrado, setInputRegistrado] = useState(false);
    const { field, formMethods } = props;
    const { errors, register, setValue, watch } = formMethods;
    const disabled = field.disabled && typeof field.disabled === 'function' ? field.disabled() : field.disabled;
    const validadores =
        field.validators && typeof field.validators === 'function' ? field.validators() : field.validators || {};
    useEffect(() => {
        if (
            !inputRegistrado &&
            ['select', 'multi-select', 'tel', 'localizacao', 'moeda', 'cpf'].indexOf(props.field.type) > -1
        ) {
            register(field.name, validadores);
            setInputRegistrado(true);
        }
    }, [inputRegistrado]);
    const label = `${field.label}${validadores.required ? ' *' : ''}`;
    const extraComponente = () => {
        return (
            <>
                {field.helpText ? <FormHelperText>{field.helpText}</FormHelperText> : ''}
                <ErrorMessage
                    errors={errors}
                    name={field.name}
                    render={({ message, messages }) => {
                        if (message) {
                            return <span className="form-text text-danger mt-0">{message}</span>;
                        }
                        if (messages) {
                            return Object.keys(messages).map((x) => (
                                <span key={x} className="form-text text-danger mt-0">
                                    {messages[x]}
                                    <br />
                                </span>
                            ));
                        }
                        return <div />;
                    }}
                />
            </>
        );
    };
    const isInvalid = !!errors[field.name];
    if (field.visivel === false) {
        return <></>;
    }
    switch (field.type) {
        case 'hidden':
            return <input type="hidden" ref={(ref) => register(ref, validadores)} name={field.name} />;
        case 'datetime-local':
        case 'date':
            return (
                <div key={field.name} className={'mb-2'}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        id={field.name}
                        label={label}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                        onChange={(inputEvent) => {
                            setValue(field.name, inputEvent);
                        }}
                        value={watch(field.name)}
                        defaultValue={props.field.valueDefault}
                        inputRef={(ref) => register(field.name, validadores)}
                        fullWidth
                        margin="normal"
                        className="mt-1"
                        error={isInvalid}
                    />
                    {extraComponente()}
                </div>
            );
        case 'moeda':
            return (
                <div key={field.name} className={'mb-2'}>
                    <NumberFormat
                        customInput={TextField}
                        thousandSeparator={'.'}
                        decimalScale={2}
                        allowNegative
                        allowedDecimalSeparators={[',']}
                        decimalSeparator={','}
                        allowEmptyFormatting
                        prefix={'R$ '}
                        type="tel"
                        autoComplete="off"
                        label={label}
                        InputProps={{
                            readOnly: field.readonly
                        }}
                        name={field.name}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        fullWidth
                        margin="normal"
                        className="mt-1"
                        error={isInvalid}
                        value={watch(field.name)}
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            setValue(field.name, floatValue);
                        }}
                    />
                    {extraComponente()}
                </div>
            );
        case 'email':
        case 'number':
            return (
                <div key={field.name} className={'mb-2'}>
                    <TextField
                        autoComplete="off"
                        label={label}
                        InputProps={{
                            readOnly: field.readonly
                        }}
                        name={field.name}
                        type={field.type}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        inputRef={(ref) => register(ref, validadores)}
                        fullWidth
                        margin="normal"
                        className="mt-1"
                        error={isInvalid}
                    />
                    {extraComponente()}
                </div>
            );
        case 'tel':
            return (
                <div key={field.name} className={'mb-2'}>
                    <InputMask
                        mask="(99) 9999-99999"
                        disabled={!!disabled}
                        name={field.name}
                        defaultValue={field.valueDefault}
                        onChange={(event) => setValue(props.field.name, event.target.value)}
                        value={watch(props.field.name)}>
                        {(inputProps: any) => (
                            <TextField
                                {...inputProps}
                                autoComplete="off"
                                label={label}
                                InputProps={{
                                    readOnly: field.readonly
                                }}
                                type={field.type}
                                placeholder={field.placeholder}
                                fullWidth
                                margin="normal"
                                className="mt-1"
                                disableUnderline
                                error={isInvalid}
                            />
                        )}
                    </InputMask>
                    {extraComponente()}
                </div>
            );
        case 'cpf':
            return (
                <div key={field.name} className={'mb-2'}>
                    <InputMask
                        mask="999.999.999-99"
                        disabled={!!disabled}
                        name={field.name}
                        defaultValue={field.valueDefault}
                        onChange={(event) => setValue(props.field.name, event.target.value)}
                        value={watch(props.field.name)}>
                        {(inputProps: any) => (
                            <TextField
                                {...inputProps}
                                autoComplete="off"
                                label={label}
                                InputProps={{
                                    readOnly: field.readonly
                                }}
                                type={field.type}
                                placeholder={field.placeholder}
                                fullWidth
                                margin="normal"
                                className="mt-1"
                                disableUnderline
                                error={isInvalid}
                            />
                        )}
                    </InputMask>
                    {extraComponente()}
                </div>
            );
        case 'text':
        case 'password':
            return (
                <div key={field.name} className={'mb-2'}>
                    <TextField
                        label={label}
                        autoComplete="off"
                        InputProps={{
                            readOnly: field.readonly
                        }}
                        name={field.name}
                        type={field.type}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        defaultValue={field.valueDefault}
                        inputRef={(ref) => register(ref, validadores)}
                        fullWidth
                        margin="normal"
                        className="mt-1"
                        error={isInvalid}
                    />
                    {extraComponente()}
                </div>
            );
        case 'text-area':
            return (
                <div key={field.name} className={'mb-2'}>
                    <TextField
                        multiline
                        label={label}
                        disabled={!!disabled}
                        name={field.name}
                        placeholder={field.placeholder}
                        autoComplete="off"
                        InputProps={{
                            readOnly: field.readonly
                        }}
                        // defaultValue={field.valueDefault}
                        error={isInvalid}
                        inputRef={(ref) => register(ref, validadores)}
                        fullWidth
                        margin="normal"
                        className="mt-1"
                    />
                    {extraComponente()}
                </div>
            );
        case 'select':
        case 'multi-select':
            return (
                <div key={field.name} className={'mb-2'}>
                    <Select2
                        label={label}
                        fullWidth
                        className="w-100"
                        onChange={(selected) => {
                            setValueSelect(selected);
                            if (Array.isArray(selected)) {
                                setValue(
                                    field.name,
                                    selected.map((x) => x.value)
                                );
                            } else {
                                setValue(field.name, selected?.value || null);
                            }
                        }}
                        multiple={field.type === 'multi-select'}
                        disabled={!!disabled}
                        placeholder={field.placeholder}
                        error={isInvalid}
                        options={props.field.choices ? props.field.choices : []}
                        value={field.type === 'multi-select' && !valueSelect ? [] : valueSelect}
                    />
                    {extraComponente()}
                </div>
            );
        case 'localizacao':
            return (
                <div key={field.name} className="py-3 mb-2">
                    <InputLabel>{label}</InputLabel>
                    <LocalizacaoInput
                        value={watch(field.name)}
                        className="mb-2"
                        defaultLocation={field.valueDefault}
                        onChange={(value) => {
                            setValue(field.name, value);
                        }}
                    />
                    {extraComponente()}
                </div>
            );

        default:
            return <div />;
    }
}
