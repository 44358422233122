import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import { Loader } from 'components/Loader';
import { Usuario } from 'models/Usuario';
import { ControleDePaginacao } from 'components/ControleDePaginacao';
import { Paginacao } from 'util/Paginacao';
import { Formulario } from 'components/Formulario';
import { UsuarioService } from 'services/UsuarioService';

export function UsuariosPage(props: RouteComponentProps) {
    const [paginacaoUsuarios, setPaginacaoUsuarios] = useState<Paginacao<Usuario> | null>();
    const [usuarios, setUsuarios] = useState<Usuario[]>();
    const [usuarioForm, setUsuarioForm] = useState<Usuario>();
    const [showFormUsuario, setShowFormUsuario] = useState(false);
    const [showModalEdicao, setShowModalEdicao] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    useEffect(() => {
        if (paginacaoUsuarios === undefined) {
            (async () => {
                try {
                    const pg: Paginacao<Usuario> = await UsuarioService.obterListaDeUsuarios();
                    setPaginacaoUsuarios(pg);
                    setUsuarios(await pg.getItensProximaPagina());
                } catch {
                    setUsuarios([]);
                    setPaginacaoUsuarios(null);
                }
            })();
        }
    });
    const atualizarUsuarioNaPagina = (usuarioEditado: Usuario) => {
        setUsuarios((u) => {
            const users = [...(u || [])];
            const i = users.findIndex((x) => x.id === usuarioEditado.id);
            if (i > -1) {
                users[i] = new Usuario(usuarioEditado);
            } else {
                users.push(new Usuario(usuarioEditado));
            }
            return users;
        });
    };
    const handleSubmitUsuario = (data: any, callbackErro?: any) => {
        setLoading(true);
        UsuarioService.cadastrarUsuario(data).then(
            (usuarioEditado) => {
                atualizarUsuarioNaPagina(usuarioEditado);
                setLoading(false);
                setShowFormUsuario(false);
            },
            (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data);
                }
                setLoading(false);
            }
        );
    };

    const handleSubmitEdicao = (data: any, callbackErro?: any, form?: HTMLFormElement | null) => {
        setLoading(true);
        UsuarioService.atualizarUsuario({ ...usuarioForm, ...data }).then(
            (user) => {
                atualizarUsuarioNaPagina(user);
                setUsuarioForm(user);
                setLoading(false);
                setSaveSuccess(true);
            },
            (err) => {
                setSaveSuccess(false);
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data);
                }
                setLoading(false);
            }
        );
    };

    return (
        <div className="app-wrapper">
            <Card>
                <CardBody>
                    <CardTitle>Usuários Administrativos</CardTitle>
                    <hr />
                    <div>
                        <p>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setUsuarioForm(new Usuario());
                                    setShowFormUsuario(true);
                                }}>
                                Novo Usuário
                            </button>
                        </p>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Email</th>
                                        <th>Permissões</th>
                                        <th>Ativo</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usuarios === undefined && (
                                        <tr>
                                            <td colSpan={5}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    )}
                                    {usuarios &&
                                        usuarios.map((u) => {
                                            return (
                                                <tr key={u.id}>
                                                    <td>{u.nome}</td>
                                                    <td>{u.email}</td>
                                                    <td>{u.getPermissoesDisplay()}</td>
                                                    <td>
                                                        {u.ativo ? (
                                                            <Badge color="success">Sim</Badge>
                                                        ) : (
                                                            <Badge color="danger">Não</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={() => {
                                                                setUsuarioForm(u);
                                                                setShowModalEdicao(true);
                                                            }}>
                                                            ...
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {usuarios && usuarios.length === 0 && (
                                        <tr>
                                            <td colSpan={5}>Nenhum usuário administrativo cadastrado</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    {paginacaoUsuarios && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoUsuarios.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setUsuarios(undefined);
                                paginacaoUsuarios.getItensDaPagina(pg).then((us) => {
                                    setUsuarios(us);
                                });
                            }}
                        />
                    )}
                </CardFooter>
            </Card>
            <Modal isOpen={showFormUsuario} toggle={() => setShowFormUsuario((s) => !s)}>
                <ModalHeader toggle={() => setShowFormUsuario((s) => !s)}>Usuário Administrativo</ModalHeader>
                <ModalBody>
                    {usuarioForm && (
                        <Formulario
                            name="Perfil"
                            fields={usuarioForm.getFieldsFormulario()}
                            loading={loading}
                            onSubmit={handleSubmitUsuario}
                            textSubmitButton="Salvar"
                        />
                    )}
                </ModalBody>
            </Modal>
            <Modal isOpen={showModalEdicao} toggle={() => setShowModalEdicao((s) => !s)}>
                <ModalHeader toggle={() => setShowModalEdicao((s) => !s)}>Usuário Administrativo</ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === '1' ? 'active' : ''}
                                onClick={() => {
                                    setActiveTab('1');
                                }}>
                                Dados de Cadastro
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '2' ? 'active' : ''}
                                onClick={() => {
                                    setActiveTab('2');
                                }}>
                                Senha
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === '3' ? 'active' : ''}
                                onClick={() => {
                                    setActiveTab('3');
                                }}>
                                Acesso
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Card>
                                <CardBody>
                                    {saveSuccess && activeTab === '2' && (
                                        <Alert color="success">Dados atualizado com sucesso!</Alert>
                                    )}
                                    {usuarioForm && (
                                        <Formulario
                                            name="Perfil"
                                            fields={usuarioForm.getFieldsFormulario()}
                                            loading={loading}
                                            onSubmit={handleSubmitEdicao}
                                            textSubmitButton="Salvar"
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="2">
                            <Card>
                                <CardBody>
                                    {saveSuccess && activeTab === '2' && (
                                        <Alert color="success">Senha alterada com sucesso!</Alert>
                                    )}
                                    <h3>Alterar Senha</h3>
                                    <Formulario
                                        loading={loading}
                                        name={'FormSenha'}
                                        fields={[
                                            {
                                                name: 'senha',
                                                type: 'password',
                                                label: 'Nova Senha',
                                                validators: {
                                                    required: {
                                                        value: true,
                                                        message: 'Nova Senha é obrigatório'
                                                    }
                                                }
                                            },
                                            {
                                                name: 'confirmar_senha',
                                                type: 'password',
                                                label: 'Confirmar Senha',
                                                validators: {
                                                    required: {
                                                        value: true,
                                                        message: 'Confirmação é obrigatório'
                                                    }
                                                }
                                            }
                                        ]}
                                        onSubmit={handleSubmitEdicao}
                                        textSubmitButton="Salvar"
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="3">
                            <Card>
                                <CardBody>
                                    {saveSuccess && activeTab === '3' && (
                                        <Alert color="success">Operação realizada com sucesso!</Alert>
                                    )}
                                    <h3>Acesso ao sistema</h3>
                                    <p className="text-center">
                                        <ButtonToolbar>
                                            <ButtonGroup>
                                                <Button
                                                    disabled={!usuarioForm?.ativo}
                                                    color="danger"
                                                    onClick={() => {
                                                        handleSubmitEdicao({ ativo: false });
                                                    }}>
                                                    Bloquear Acesso
                                                </Button>
                                                <Button
                                                    disabled={usuarioForm?.ativo}
                                                    color="success"
                                                    onClick={() => {
                                                        handleSubmitEdicao({ ativo: true });
                                                    }}>
                                                    Liberar Acesso
                                                </Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </p>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>
        </div>
    );
}
