import {ApiService} from 'services/ApiService';

export declare interface PaginacaoModel<T> {
    results: T[];
    previous: string;
    next: string;
    count: number;
    num_pages: number;
    extra: any;
}

export class Paginacao<T> {
    private itensPorPagina: { [id: number]: T[]; } = {};
    private requestEmAndamento: boolean = false;
    private num_pages: number;
    private quantidade: number;
    public paginaAtual: number;
    private _extra: any;

    constructor(private partialUrl: string, private typeModel: new (defaultValues?: any) => T, paginacaoModel: PaginacaoModel<T>, private parms?: { [name: string]: any }) {
        this.paginaAtual = 0;
        this.itensPorPagina[0] = paginacaoModel.results.map((x) => new this.typeModel(x));
        this.num_pages = paginacaoModel.num_pages;
        this.quantidade = paginacaoModel.count;
        this._extra = paginacaoModel.extra;
    }

    public getExtraData() {
        return this._extra;
    }

    public getQuantidadeTotalDeItens() {
        return this.quantidade;
    }

    public getQuantidadeDePaginas() {
        return this.num_pages;
    }

    public temProximaPagina() {
        return this.paginaAtual < this.num_pages;
    }

    public temPaginaAnterior() {
        return this.paginaAtual > 1;
    }

    public getItensProximaPagina() {
        if (!this.temProximaPagina()) {
            return [];
        }
        return this.getItensDaPagina(this.paginaAtual + 1);
    }

    public getItensPaginaAnterior() {
        if (!this.temPaginaAnterior()) {
            return [];
        }
        this.paginaAtual--;
        return this.getItensDaPagina(this.paginaAtual - 1);
    }

    public getItensDaPagina(pagina: number): Promise<T[]> {
        this.paginaAtual = pagina;
        return new Promise((resolve, reject) => {
            const intervar = setInterval(async () => {
                if (!this.requestEmAndamento) {
                    if (this.itensPorPagina[pagina - 1]) {
                        resolve(this.itensPorPagina[pagina - 1]);
                    } else {
                        this.requestEmAndamento = true;
                        await this.getPage(pagina).then(resolve, reject);
                        this.requestEmAndamento = false;
                    }
                    clearInterval(intervar);
                }
            }, 20);
        });
    }

    private getPage(pagina: number): Promise<T[]> {
        if (!this.parms) {
            this.parms = {};
        }
        this.parms['page'] = String(pagina);

        return ApiService.getAll(this.partialUrl, this.parms).then((p: PaginacaoModel<T>) => {
            const resultado = p.results.map((x) => new this.typeModel(x))
            this.itensPorPagina[pagina - 1] = resultado;
            this.num_pages = p.num_pages;
            this.requestEmAndamento = false;
            return resultado;
        })
    }
}
