import enMessages from '../locales/pt_BR.json';

const PtLang = {
    messages: {
        ...enMessages
    },
    locale: 'pt-BR',
};

export default PtLang;
