import {Empreendimento} from 'models/Empreemdimento';
import {IJsonFromField} from 'components/InputFormulario/types';
import {ApiService} from 'services/ApiService';
import {Cliente} from 'models/Cliente';


export class DenuciasPrestador {
    public id?: number
    public prestador?: PrestadorDeServico
    public cliente?: Cliente
    public comentario?: string
    public data_cadastro?: string

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            })
        }
    }
}

export class AvaliacaoPrestador {
    public id?: number
    public prestador?: PrestadorDeServico
    public cliente?: Cliente
    public avaliacao?: number
    public comentario?: string
    public aprovada?: boolean
    public data_cadastro?: string

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            })
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'aprovada',
                label: 'Avaliação aprovada?',
                type: 'text',
                validators: {
                    required: {
                        value: true,
                        message: 'Aprivação é obrigatório'
                    }
                },
                valueDefault: this.aprovada !== undefined ? {
                    value: String(this.aprovada),
                    label: this.aprovada ? 'Sim' : 'Não'
                } : undefined,
                choices: [
                    {
                        label: 'Sim',
                        value: 'true'
                    },
                    {
                        label: 'Não',
                        value: 'false'
                    },
                ]
            },
        ]
    }
}

export class CategoriaServico {
    public id?: number
    public nome?: string
    public data_cadastro?: string

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            })
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'nome',
                label: 'Nome da Categoria',
                type: 'text',
                valueDefault: this.nome,
                validators: {
                    required: {
                        value: true,
                        message: 'Nome é obrigatório'
                    }
                }
            },
        ]
    }
}

export class PrestadorDeServico {
    public id?: number
    public nome?: string
    public descricao?: string
    public telefone?: string
    public whatsapp?: string
    public email?: string
    public site?: string
    public foto?: string
    public ativo?: boolean
    public categorias?: number[]
    public categorias_detalhes?: CategoriaServico[]
    public data_cadastro?: string
    public empreendimento?: number
    public empreendimento_detalhe?: Empreendimento

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                if (key === 'empreendimento_detalhe') {
                    obj[key] = new Empreendimento(defaultValue[key]);
                } else if (key === 'categorias_detalhes') {
                    obj[key] = defaultValue[key].map((x: any) => {
                        return new CategoriaServico(x);
                    });
                } else {
                    obj[key] = defaultValue[key];
                }
            })
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'nome',
                label: 'Nome',
                type: 'text',
                valueDefault: this.nome,
                validators: {
                    required: {
                        value: true,
                        message: 'Nome é obrigatório'
                    }
                }
            },
            {
                name: 'descricao',
                label: 'Descrição',
                type: 'text-area',
                valueDefault: this.descricao,
                validators: {
                    required: {
                        value: true,
                        message: 'Descrição é obrigatório'
                    }
                }
            },
            {
                type: 'select',
                name: 'empreendimento',
                label: 'Empreendimento',
                valueDefault: this.empreendimento_detalhe ? {
                    value: this.empreendimento_detalhe.id,
                    label: this.empreendimento_detalhe.nome
                } : undefined,
                choices: () => {
                    return ApiService.getAll('/empreendimentos', {paginate: 'none'}).then((e: Empreendimento[]) => {
                        return e.map(x => {
                            return {
                                label: String(x.nome),
                                value: x.id
                            }
                        })
                    })
                }
            },
            {
                type: 'multi-select',
                name: 'categorias',
                label: 'Categorias',
                valueDefault: this.categorias_detalhes ? this.categorias_detalhes.map(x => {
                    return {
                        value: x.id,
                        label: x.nome
                    }
                }) : undefined,
                choices: () => {
                    return ApiService.getAll('/categorias-servicos').then((e: CategoriaServico[]) => {
                        return e.map(x => {
                            return {
                                label: String(x.nome),
                                value: x.id
                            }
                        })
                    })
                }
            },
            {
                name: 'email',
                label: 'Email',
                type: 'email',
                valueDefault: this.email,
            },
            {
                name: 'telefone',
                label: 'Telefone',
                type: 'tel',
                valueDefault: this.telefone
            },
            {
                name: 'whatsapp',
                label: 'Whatsapp',
                type: 'tel',
                valueDefault: this.whatsapp
            },
            {
                name: 'site',
                label: 'Site',
                type: 'text',
                valueDefault: this.site,
                helpText: 'Informar URL completa ex: https://www.google.com'
            },
            {
                name: 'ativo',
                label: 'Ativo?',
                type: 'select',
                valueDefault: this.ativo !== undefined ? {
                    value: String(this.ativo),
                    label: this.ativo ? 'Sim' : 'Não'
                } : {
                    label: 'Sim',
                    value: 'true'
                },
                validators: {
                    required: {
                        value: true,
                        message: 'Status é obrigatório'
                    }
                },
                choices: [
                    {
                        label: 'Sim',
                        value: 'true'
                    },
                    {
                        label: 'Não',
                        value: 'false'
                    },
                ]
            }
        ];
    }
}

