import React from 'react';
import Routes from '../../app/routes';
import Vertical from 'containers/AppLayout/Vertical';

const AppLayout = () => {
    return (
        <Vertical>
            <Routes/>
        </Vertical>
    );
};

export default AppLayout;
