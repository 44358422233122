import { Avatar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ControleDePaginacao } from 'components/ControleDePaginacao';
import { BtnSelectAndCropImage } from 'components/CropImage';
import { Formulario } from 'components/Formulario';
import { Loader } from 'components/Loader';
import { Noticia } from 'models/Noticia';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap';
import { ApiService } from 'services/ApiService';
import { Paginacao } from 'util/Paginacao';

export function NoticiaPage(props: RouteComponentProps) {
    const [
        paginacaoNoticias,
        setPaginacaoNoticias,
    ] = useState<Paginacao<Noticia> | null>();
    const [noticias, setNoticias] = useState<Noticia[]>();
    const [noticiaForm, setNoticiaForm] = useState<Noticia>();
    const [showFormNoticia, setShowFormNoticia] = useState(false);
    const [loading, setLoading] = useState(false);
    const [novaImagem, setNovaImagem] = useState<File>();

    useEffect(() => {
        if (paginacaoNoticias === undefined) {
            (async () => {
                try {
                    const pg: Paginacao<Noticia> = new Paginacao<Noticia>(
                        '/noticias',
                        Noticia,
                        await ApiService.getAll('/noticias')
                    );
                    setPaginacaoNoticias(pg);
                    setNoticias(await pg.getItensProximaPagina());
                } catch {
                    setNoticias([]);
                    setPaginacaoNoticias(null);
                }
            })();
        }
    });
    const atualizarNoticiaNaPagina = (usuarioEditado: Noticia) => {
        setNoticias((u) => {
            const users = [...(u || [])];
            const i = users.findIndex((x) => x.id === usuarioEditado.id);
            if (i > -1) {
                users[i] = new Noticia(usuarioEditado);
            } else {
                users.push(new Noticia(usuarioEditado));
            }
            return users;
        });
    };
    const handleSubmitNoticia = (data: any, callbackErro?: any) => {
        setLoading(true);
        let novaData: any = data;
        if (novaImagem) {
            novaData = new FormData();
            novaData.set('foto', novaImagem, 'foto_principal.png');
            Object.keys(data).forEach((key) => {
                novaData.append(key, data[key]);
            });
        }
        if (noticiaForm?.id) {
            ApiService.put('/noticias', String(noticiaForm?.id), novaData).then(
                (noticia) => {
                    atualizarNoticiaNaPagina(noticia);
                    setLoading(false);
                    setShowFormNoticia(false);
                    setNovaImagem(undefined);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                }
            );
        } else {
            ApiService.post('/noticias', novaData).then(
                (noticia) => {
                    atualizarNoticiaNaPagina(noticia);
                    setLoading(false);
                    setShowFormNoticia(false);
                    setNovaImagem(undefined);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                }
            );
        }
    };

    const handleSubmitLogo = async (logo: HTMLCanvasElement) => {
        setNovaImagem(
            new File(
                [
                    await new Promise<Blob>((resolve, reject) => {
                        logo.toBlob((b) => {
                            if (b) resolve(b);
                            else reject();
                        });
                    }),
                ],
                'imagem_pricimal.png'
            )
        );
    };

    return (
        <div className="app-wrapper">
            <Card>
                <CardBody>
                    <CardTitle>Notícias</CardTitle>
                    <hr />
                    <div>
                        <p>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setNoticiaForm(new Noticia());
                                    setShowFormNoticia(true);
                                }}
                            >
                                Nova Notícia
                            </button>
                        </p>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Titulo</th>
                                        <th>Empreendimento</th>
                                        <th>Data Cadastro</th>
                                        <th>Ativo</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {noticias === undefined && (
                                        <tr>
                                            <td colSpan={5}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    )}
                                    {noticias &&
                                        noticias.map((u) => {
                                            return (
                                                <tr key={u.id}>
                                                    <td>{u.titulo}</td>
                                                    <td>
                                                        {
                                                            u
                                                                ?.empreendimento_detalhe
                                                                ?.nome
                                                        }
                                                    </td>
                                                    <td>
                                                        {moment(
                                                            u.data_cadastro
                                                        ).format('DD/MM/YYYY')}
                                                    </td>
                                                    <td>
                                                        {u.ativa ? (
                                                            <Badge color="success">
                                                                Sim
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger">
                                                                Não
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={() => {
                                                                setNoticiaForm(
                                                                    u
                                                                );
                                                                setShowFormNoticia(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <EditIcon
                                                                fontSize={
                                                                    'small'
                                                                }
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {noticias && noticias.length === 0 && (
                                        <tr>
                                            <td colSpan={5}>
                                                Nenhuma notícia cadastrada
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    {paginacaoNoticias && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoNoticias.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setNoticias(undefined);
                                paginacaoNoticias
                                    .getItensDaPagina(pg)
                                    .then((us) => {
                                        setNoticias(us);
                                    });
                            }}
                        />
                    )}
                </CardFooter>
            </Card>
            <Modal
                isOpen={showFormNoticia}
                toggle={() => {
                    setShowFormNoticia((s) => !s);
                    setNovaImagem(undefined);
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setShowFormNoticia((s) => !s);
                        setNovaImagem(undefined);
                    }}
                >
                    Cadastro de Notícia
                </ModalHeader>
                <ModalBody>
                    {noticiaForm && (
                        <div>
                            <h3>Foto Principal</h3>
                            <div className="text-center mt-4">
                                <BtnSelectAndCropImage
                                    onSave={(imageCrop) => {
                                        handleSubmitLogo(imageCrop);
                                    }}
                                >
                                    <Avatar
                                        className="mx-auto"
                                        variant="rounded"
                                        style={{
                                            width: '300px',
                                            height: '300px',
                                        }}
                                        src={
                                            novaImagem
                                                ? URL.createObjectURL(
                                                      novaImagem
                                                  )
                                                : noticiaForm?.foto
                                        }
                                    />
                                </BtnSelectAndCropImage>
                            </div>
                            <Formulario
                                name="CadastroNoticia"
                                fields={noticiaForm.getFieldsFormulario()}
                                loading={loading}
                                onSubmit={handleSubmitNoticia}
                                textSubmitButton="Salvar"
                            />
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
}
