import { Avatar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import { ControleDePaginacao } from 'components/ControleDePaginacao';
import { BtnSelectAndCropImage } from 'components/CropImage';
import { Formulario } from 'components/Formulario';
import { Loader } from 'components/Loader';
import {
    AvaliacaoPrestador,
    CategoriaServico,
    DenuciasPrestador,
    PrestadorDeServico,
} from 'models/PrestadorDeServico';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import { ApiService } from 'services/ApiService';
import { Paginacao } from 'util/Paginacao';

export function PrestadoresPage(props: RouteComponentProps) {
    const [tab, setTab] = useState<'1' | '2' | '3' | '4'>('1');
    const [
        paginacaoPrestadores,
        setPaginacaoPrestadores,
    ] = useState<Paginacao<PrestadorDeServico> | null>();
    const [
        paginacaoAvaliacoes,
        setPaginacaoAvaliacoes,
    ] = useState<Paginacao<AvaliacaoPrestador> | null>();
    const [
        paginacaoDenuncias,
        setPaginacaoDenucias,
    ] = useState<Paginacao<DenuciasPrestador> | null>();
    const [prestadores, setPrestadores] = useState<PrestadorDeServico[]>();
    const [avaliacoes, setAvaliacoes] = useState<AvaliacaoPrestador[]>();
    const [categorias, setCategorias] = useState<CategoriaServico[]>();
    const [denuncias, setDenucias] = useState<DenuciasPrestador[]>();
    const [prestadorForm, setPrestadorForm] = useState<PrestadorDeServico>();
    const [categoriaForm, setCategoriaForm] = useState<CategoriaServico>();
    const [loading, setLoading] = useState(false);
    const [saveSucess, setSaveSuccess] = useState(false);
    const [showModalPrestador, setShowModalPrestador] = useState(false);
    const [showModalCategoria, setShowModalCategoria] = useState(false);
    const [novaImagem, setNovaImagem] = useState<File>();

    const handleSubmit = (
        data: any,
        callbackErro?: any,
        form?: HTMLFormElement | null
    ) => {
        setLoading(true);
        let novaData: any = { ...prestadorForm, ...data };
        if (novaImagem) {
            novaData = new FormData();
            novaData.set('foto', novaImagem, 'foto_principal.png');
            Object.keys(data).forEach((key) => {
                if (key === 'categorias') {
                    data.categorias.forEach((x: string) => {
                        novaData.append(key, x);
                    });
                } else {
                    novaData.append(key, data[key]);
                }
            });
        } else {
            delete novaData.foto;
        }
        if (prestadorForm?.id) {
            ApiService.put(
                '/prestadores',
                String(prestadorForm.id),
                novaData
            ).then(
                (pr) => {
                    setPrestadores((p) => {
                        const prests = [...(p || [])];
                        const idx = p?.findIndex((x) => x.id === pr.id);
                        if (idx !== undefined && idx > -1) {
                            prests[idx] = new PrestadorDeServico(pr);
                        }
                        return prests;
                    });
                    setShowModalPrestador(false);
                    setLoading(false);
                    setNovaImagem(undefined);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        } else {
            ApiService.post('/prestadores', novaData).then(
                (pr) => {
                    setPrestadores((p) => {
                        return [new PrestadorDeServico(pr), ...(p || [])];
                    });
                    setLoading(false);
                    setShowModalPrestador(false);
                    setNovaImagem(undefined);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        }
    };
    const handleSubmitCategoria = (
        data: any,
        callbackErro?: any,
        form?: HTMLFormElement | null
    ) => {
        setLoading(true);
        if (categoriaForm?.id) {
            ApiService.put('/categorias-servicos', String(categoriaForm.id), {
                ...categoriaForm,
                ...data,
            }).then(
                (pr) => {
                    setCategorias((p) => {
                        const prests = [...(p || [])];
                        const idx = p?.findIndex((x) => x.id === pr.id);
                        if (idx !== undefined && idx > -1) {
                            prests[idx] = new CategoriaServico(pr);
                        }
                        return prests;
                    });
                    setLoading(false);
                    setShowModalCategoria(false);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        } else {
            ApiService.post('/categorias-servicos', {
                ...categoriaForm,
                ...data,
            }).then(
                (pr) => {
                    setCategorias((p) => {
                        return [new CategoriaServico(pr), ...(p || [])];
                    });
                    setLoading(false);
                    setShowModalCategoria(false);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        }
    };

    const handleSubmitLogo = async (logo: HTMLCanvasElement) => {
        setNovaImagem(
            new File(
                [
                    await new Promise<Blob>((resolve, reject) => {
                        logo.toBlob((b) => {
                            if (b) resolve(b);
                            else reject();
                        });
                    }),
                ],
                'imagem_pricimal.png'
            )
        );
    };

    useEffect(() => {
        if (paginacaoPrestadores === undefined && tab === '1') {
            (async () => {
                try {
                    const pg: Paginacao<PrestadorDeServico> = new Paginacao<PrestadorDeServico>(
                        '/prestadores',
                        PrestadorDeServico,
                        await ApiService.getAll('/prestadores')
                    );
                    setPaginacaoPrestadores(pg);
                    setPrestadores(await pg.getItensProximaPagina());
                } catch {
                    setPrestadores([]);
                    setPaginacaoPrestadores(null);
                }
            })();
        }
        if (categorias === undefined && tab === '2') {
            (async () => {
                try {
                    const cats = await ApiService.getAll(
                        '/categorias-servicos'
                    );
                    setCategorias(
                        cats.map((x: any) => new CategoriaServico(x))
                    );
                } catch {
                    setCategorias([]);
                }
            })();
        }
        if (paginacaoAvaliacoes === undefined && tab === '3') {
            (async () => {
                try {
                    const pg: Paginacao<AvaliacaoPrestador> = new Paginacao<AvaliacaoPrestador>(
                        '/avaliacoes-prestadores',
                        AvaliacaoPrestador,
                        await ApiService.getAll('/avaliacoes-prestadores')
                    );
                    setPaginacaoAvaliacoes(pg);
                    setAvaliacoes(await pg.getItensProximaPagina());
                } catch {
                    setAvaliacoes([]);
                    setPaginacaoAvaliacoes(null);
                }
            })();
        }
        if (paginacaoDenuncias === undefined && tab === '4') {
            (async () => {
                try {
                    const pg: Paginacao<DenuciasPrestador> = new Paginacao<DenuciasPrestador>(
                        '/denuncias-prestadores',
                        DenuciasPrestador,
                        await ApiService.getAll('/denuncias-prestadores')
                    );
                    setPaginacaoDenucias(pg);
                    setDenucias(await pg.getItensProximaPagina());
                } catch {
                    setDenucias([]);
                    setPaginacaoDenucias(null);
                }
            })();
        }
    });

    const toggleAprovacaoAvaliacao = (avaliacao: AvaliacaoPrestador) => {
        if (
            window.confirm(
                avaliacao.aprovada
                    ? 'Dejesa realmente reprovar essa avaliação'
                    : 'Dejesa reamente aprovar essa avaliação?'
            )
        ) {
            setLoading(true);
            ApiService.put('/avaliacoes-prestadores', String(avaliacao.id), {
                aprovada: !avaliacao.aprovada,
            }).then(
                (x) => {
                    setAvaliacoes((a) => {
                        const as = [...(a || [])];
                        const idx = as.findIndex((aa) => aa.id === x.id);
                        if (idx !== undefined && idx > -1) {
                            as[idx] = new AvaliacaoPrestador(x);
                        }
                        return as;
                    });
                    setLoading(false);
                },
                () => setLoading(false)
            );
        }
    };
    return (
        <div className="app-wrapper">
            <Card>
                <CardBody>
                    <CardTitle>Prestadores de Serviço</CardTitle>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={tab === '1' ? 'active' : ''}
                                onClick={() => {
                                    setTab('1');
                                    setSaveSuccess(false);
                                }}
                            >
                                Prestadores
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tab === '2' ? 'active' : ''}
                                onClick={() => {
                                    setTab('2');
                                    setSaveSuccess(false);
                                }}
                            >
                                Categorias
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tab === '3' ? 'active' : ''}
                                onClick={() => {
                                    setTab('3');
                                    setSaveSuccess(false);
                                }}
                            >
                                Avaliações
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tab === '4' ? 'active' : ''}
                                onClick={() => {
                                    setTab('4');
                                    setSaveSuccess(false);
                                }}
                            >
                                Denúncias
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                            <div className="p-5">
                                {saveSucess && tab === '1' && (
                                    <Alert color="success">
                                        Dados atualizados com sucesso!
                                    </Alert>
                                )}
                                <p>
                                    <button
                                        className={'btn btn-primary'}
                                        onClick={() => {
                                            setNovaImagem(undefined);
                                            setPrestadorForm(
                                                new PrestadorDeServico()
                                            );
                                            setShowModalPrestador(true);
                                        }}
                                    >
                                        Novo Prestador
                                    </button>
                                </p>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Telefone</th>
                                                <th>Email</th>
                                                <th>Status</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prestadores === undefined && (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            )}
                                            {prestadores &&
                                                prestadores.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{u.nome}</td>
                                                            <td>
                                                                {u.telefone?.replace(
                                                                    '_',
                                                                    ''
                                                                )}
                                                            </td>
                                                            <td>{u.email}</td>
                                                            <td>
                                                                {u.ativo
                                                                    ? 'Ativo'
                                                                    : 'Inativo'}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-light"
                                                                    onClick={() => {
                                                                        setNovaImagem(
                                                                            undefined
                                                                        );
                                                                        setPrestadorForm(
                                                                            u
                                                                        );
                                                                        setShowModalPrestador(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <EditIcon
                                                                        fontSize={
                                                                            'small'
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {prestadores &&
                                                prestadores.length === 0 && (
                                                    <tr>
                                                        <td colSpan={5}>
                                                            Nenhum prestador de
                                                            serviço cadastrado
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="p-5">
                                {saveSucess && tab === '2' && (
                                    <Alert color="success">
                                        Dados atualizados com sucesso!
                                    </Alert>
                                )}
                                <p>
                                    <button
                                        className={'btn btn-primary'}
                                        onClick={() => {
                                            setCategoriaForm(
                                                new CategoriaServico()
                                            );
                                            setShowModalCategoria(true);
                                        }}
                                    >
                                        Nova Categoria
                                    </button>
                                </p>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Data de Cadastro</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categorias === undefined && (
                                                <tr>
                                                    <td colSpan={3}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            )}
                                            {categorias &&
                                                categorias.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{u.nome}</td>
                                                            <td>
                                                                {moment(
                                                                    u.data_cadastro
                                                                ).format(
                                                                    'DD/MM/YYYY HH:mm:ss'
                                                                )}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-light"
                                                                    onClick={() => {
                                                                        setCategoriaForm(
                                                                            u
                                                                        );
                                                                        setShowModalCategoria(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <EditIcon
                                                                        fontSize={
                                                                            'small'
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {categorias &&
                                                categorias.length === 0 && (
                                                    <tr>
                                                        <td colSpan={3}>
                                                            Nenhuma categoria de
                                                            serviço cadastrada
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="p-5">
                                {saveSucess && tab === '2' && (
                                    <Alert color="success">
                                        Dados atualizads com sucesso!
                                    </Alert>
                                )}
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Prestador</th>
                                                <th>Cliente</th>
                                                <th>Avaliação</th>
                                                <th>Comentário</th>
                                                <th>Aprovada?</th>
                                                <th>Data de Cadastro</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {avaliacoes === undefined && (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            )}
                                            {avaliacoes &&
                                                avaliacoes.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>
                                                                {
                                                                    u.prestador
                                                                        ?.nome
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    u.cliente
                                                                        ?.nome
                                                                }
                                                            </td>
                                                            <td>
                                                                {u.avaliacao}
                                                            </td>
                                                            <td>
                                                                {u.comentario}
                                                            </td>
                                                            <td>
                                                                {u.aprovada
                                                                    ? 'Sim'
                                                                    : 'Não'}
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    u.data_cadastro
                                                                ).format(
                                                                    'DD/MM/YYYY HH:mm:ss'
                                                                )}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className={
                                                                        'btn btn-' +
                                                                        (u.aprovada
                                                                            ? 'danger'
                                                                            : 'warning')
                                                                    }
                                                                    onClick={() => {
                                                                        toggleAprovacaoAvaliacao(
                                                                            u
                                                                        );
                                                                    }}
                                                                >
                                                                    {u.aprovada
                                                                        ? 'Reprovar'
                                                                        : 'Aprovar'}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {avaliacoes &&
                                                avaliacoes.length === 0 && (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            Nenhuma avaliação
                                                            cadastrada
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className="p-5">
                                {saveSucess && tab === '2' && (
                                    <Alert color="success">
                                        Dados atualizados com sucesso!
                                    </Alert>
                                )}
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Prestador</th>
                                                <th>Cliente</th>
                                                <th>Comentario</th>
                                                <th>Data de Cadastro</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {denuncias === undefined && (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            )}
                                            {denuncias &&
                                                denuncias.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>
                                                                {
                                                                    u.prestador
                                                                        ?.nome
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    u.cliente
                                                                        ?.nome
                                                                }
                                                            </td>
                                                            <td>
                                                                {u.comentario}
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    u.data_cadastro
                                                                ).format(
                                                                    'DD/MM/YYYY HH:mm:ss'
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {denuncias &&
                                                denuncias.length === 0 && (
                                                    <tr>
                                                        <td colSpan={4}>
                                                            Nenhuma denúncia
                                                            cadastrada
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
                <CardFooter>
                    {tab === '1' && paginacaoPrestadores && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoPrestadores.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setPrestadores(undefined);
                                paginacaoPrestadores
                                    .getItensDaPagina(pg)
                                    .then((us) => {
                                        setPrestadores(us);
                                    });
                            }}
                        />
                    )}
                    {tab === '3' && paginacaoAvaliacoes && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoAvaliacoes.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setAvaliacoes(undefined);
                                paginacaoAvaliacoes
                                    .getItensDaPagina(pg)
                                    .then((us) => {
                                        setAvaliacoes(us);
                                    });
                            }}
                        />
                    )}
                    {tab === '4' && paginacaoDenuncias && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoDenuncias.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setDenucias(undefined);
                                paginacaoDenuncias
                                    .getItensDaPagina(pg)
                                    .then((us) => {
                                        setDenucias(us);
                                    });
                            }}
                        />
                    )}
                </CardFooter>
            </Card>
            <Modal
                isOpen={showModalPrestador}
                toggle={() => setShowModalPrestador((s) => !s)}
            >
                <ModalHeader toggle={() => setShowModalPrestador((s) => !s)}>
                    Prestador de Serviço
                </ModalHeader>
                <ModalBody>
                    <h3>Foto Principal</h3>
                    <div className="text-center mt-4">
                        <BtnSelectAndCropImage
                            onSave={(imageCrop) => {
                                handleSubmitLogo(imageCrop);
                            }}
                        >
                            <Avatar
                                className="mx-auto"
                                variant="rounded"
                                style={{
                                    width: '300px',
                                    height: '300px',
                                }}
                                src={
                                    novaImagem
                                        ? URL.createObjectURL(novaImagem)
                                        : prestadorForm?.foto
                                }
                            />
                        </BtnSelectAndCropImage>
                    </div>
                    {prestadorForm && (
                        <Formulario
                            name="Perfil"
                            fields={prestadorForm.getFieldsFormulario()}
                            loading={loading}
                            onSubmit={handleSubmit}
                            textSubmitButton="Salvar"
                        />
                    )}
                </ModalBody>
            </Modal>
            <Modal
                isOpen={showModalCategoria}
                toggle={() => setShowModalCategoria((s) => !s)}
            >
                <ModalHeader toggle={() => setShowModalCategoria((s) => !s)}>
                    Categoria de Serviço
                </ModalHeader>
                <ModalBody>
                    {categoriaForm && (
                        <Formulario
                            name="Perfil"
                            fields={categoriaForm.getFieldsFormulario()}
                            loading={loading}
                            onSubmit={handleSubmitCategoria}
                            textSubmitButton="Salvar"
                        >
                            {categoriaForm.id && (
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                'Deseja realmente excluir essa categoria?'
                                            )
                                        ) {
                                            setLoading(true);
                                            ApiService.delete(
                                                '/categorias-servicos',
                                                String(categoriaForm?.id)
                                            ).then(
                                                () => {
                                                    setLoading(false);
                                                    setShowModalCategoria(
                                                        false
                                                    );
                                                    setCategorias((p) => {
                                                        const prests = [
                                                            ...(p || []),
                                                        ];
                                                        const idx = p?.findIndex(
                                                            (x) =>
                                                                x.id ===
                                                                categoriaForm?.id
                                                        );
                                                        if (
                                                            idx !== undefined &&
                                                            idx > -1
                                                        ) {
                                                            prests.splice(
                                                                idx,
                                                                1
                                                            );
                                                        }
                                                        return prests;
                                                    });
                                                },
                                                () => setLoading(false)
                                            );
                                        }
                                    }}
                                    disabled={loading}
                                >
                                    {loading && (
                                        <CircularProgress
                                            color={'inherit'}
                                            size={'1rem'}
                                        />
                                    )}
                                    Excluir
                                </Button>
                            )}
                        </Formulario>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
}
