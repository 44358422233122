export class UnidadeVendida {
    public id?: number;
    public empreendimento?: number;
    public empreendimento_nome?: string;
    public quadra?: string;
    public descricao?: string;
    public lote?: string;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach((key) => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            });
        }
    }
}
