//ANDAMENTOS = (
//     (0, 'Lançamento'),
//     (1, 'Em obras'),
//     (2, 'Pronto')
// )

import {IJsonFromField} from 'components/InputFormulario/types';
import {ApiService} from 'services/ApiService';
import {Estado} from 'models/Estado';

export const ANDAMENTO_EMPREENDIMENTO: { [key: string]: string } = {
    '0': 'Lançamento',
    '1': 'Em obras',
    '2': 'Pronto',
}

export interface VideoEmpreendimento {
    id?: number,
    video?: string,
    legenda?: string
}

export interface FotoEmpreendimento {
    id?: number,
    foto?: string,
    legenda?: string
}

export class Empreendimento {
    public id?: number;
    public nome?: string;
    public descricao?: string;
    public endereco?: string;
    public cidade?: number;
    public cidade_nome?: string;
    public estado?: number;
    public estado_nome?: string;
    public estado_sigla?: string;
    public contato_condominio?: string;
    public localizacao?: {
        latitude: number,
        longitude: number
    };
    public foto_principal?: string;
    public andamento?: number;
    public booking?: string;
    public whatsapp?: string;
    public fotos?: FotoEmpreendimento[];
    public videos?: VideoEmpreendimento[];
    public sobre?: {
        id: number,
        sobre: string,
        diferenciais: string
    }
    public ativo?: boolean

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach(key => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            })
        }
    }

    getAndamentoDisplay() {
        return ANDAMENTO_EMPREENDIMENTO[String(this.andamento)];
    }

    public getFieldFormularioSobre(): IJsonFromField[] {
        return [
            {
                name: 'sobre',
                label: 'Sobre',
                type: 'text-area',
                valueDefault: this.sobre?.sobre,
                validators: {
                    required: {
                        value: true,
                        message: 'Sobre é obrigatório'
                    }
                }
            },
            {
                name: 'diferenciais',
                label: 'Diferenciais',
                type: 'text-area',
                valueDefault: this.sobre?.diferenciais
            }];
    };

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'nome',
                label: 'Nome',
                type: 'text',
                valueDefault: this.nome,
                validators: {
                    required: {
                        value: true,
                        message: 'Nome é obrigatório'
                    }
                }
            },
            {
                name: 'descricao',
                label: 'Descrição',
                type: 'text-area',
                valueDefault: this.descricao,
                validators: {
                    required: {
                        value: true,
                        message: 'Descrição é obrigatório'
                    }
                }
            },
            {
                name: 'endereco',
                label: 'Endreço',
                type: 'text',
                valueDefault: this.endereco,
                validators: {
                    required: {
                        value: true,
                        message: 'Endereço é obrigatório'
                    }
                }
            },
            {
                name: 'estado',
                label: 'Estado',
                type: 'select',
                valueDefault: this.estado ? {value: this.estado, label: this.estado_sigla} : undefined,
                onChange: data => this.estado = data,
                choices: () => {
                    return ApiService.getAll('/estados').then(data => {
                        return data.map((e: Estado) => {
                            return {
                                label: e.sigla,
                                value: e.id
                            }
                        })
                    })
                }
            },
            {
                name: 'cidade',
                label: 'Cidade',
                type: 'select',
                valueDefault: this.cidade ? {value: this.cidade, label: this.cidade_nome} : undefined,
                onChange: data => this.cidade = data,
                choices: () => {
                    return ApiService.getAll('/cidades', {estado_id: this.estado}).then(data => {
                        return data.map((e: Estado) => {
                            return {
                                label: e.nome,
                                value: e.id
                            }
                        })
                    })
                }
            },
            {
                name: 'andamento',
                label: 'Andamento',
                type: 'select',
                valueDefault: this.andamento !== undefined ? {
                    value: String(this.andamento),
                    label: ANDAMENTO_EMPREENDIMENTO[String(this.andamento)]
                } : undefined,
                validators: {
                    required: {
                        value: true,
                        message: 'Andamento é obrigatório'
                    }
                },
                choices: Object.keys(ANDAMENTO_EMPREENDIMENTO).map(x => {
                    return {
                        value: String(x),
                        label: ANDAMENTO_EMPREENDIMENTO[x]
                    }
                })
            },
            {
                name: 'whatsapp',
                label: 'Whatsapp para Atendimento',
                type: 'text',
                valueDefault: this.whatsapp,
                helpText: 'Informar somente os números do telefone, Ex: 6281819999'
            },
            {
                name: 'contato_condominio',
                label: 'Telefone de contato segunda via boleto da associação',
                type: 'tel',
                valueDefault: this.contato_condominio
            },
            {
                name: 'localizacao',
                label: 'Localização',
                type: 'localizacao',
                valueDefault: this.localizacao ? {
                    lat: this.localizacao.latitude,
                    lng: this.localizacao.longitude
                } : undefined,
                validators: {
                    required: {
                        value: true,
                        message: 'Localização é obrigatório'
                    }
                }
            }
        ];
    }
}
