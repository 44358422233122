import React, {useEffect, useState} from 'react'
import GoogleMapReact from 'google-map-react';
import Place from '@material-ui/icons/Place'

const Marker = (props: any) => {
    const markerStyle = {
        height: 40,
        width: 40,
        zIndex: 10
    };

    return (
        <>
            <div style={markerStyle} className="text-danger">
                <Place fontSize="large" style={{
                    marginTop: '-40px',
                    marginLeft: '-15px'
                }}/>
            </div>
        </>
    );
};

export function LocalizacaoInput(props: { value?: { lat: number, lng: number }, defaultLocation?: { lat: number, lng: number }, onChange?: (value?: { latitude: number, longitude: number }) => void,  className?:string}) {
    const [markerPosition, setMarkerPosition] = useState<{ lat: number, lng: number } | undefined>(props.defaultLocation)
    const [defaultCenter] = useState(markerPosition || {lat: -15.7801, lng: -47.9292})
    useEffect(() => {
        if (props.value && (props.value.lat !== markerPosition?.lat || props.value?.lng !== markerPosition?.lng)) {
            setMarkerPosition(props.value);
        }
    })

    return <div style={{height: '400px', width: '100%'}} className={props.className}><GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyBU0aSdNM6LFeBU0l45OYsmCB8VaB1jiZk'}}
        defaultZoom={15}
        defaultCenter={defaultCenter}
        center={markerPosition}
        yesIWantToUseGoogleMapApiInternals
        onClick={e => {
            setMarkerPosition({
                lat: e.lat,
                lng: e.lng
            });
            props.onChange && props.onChange({
                latitude: e.lat,
                longitude: e.lng
            });
        }}
    >
        {markerPosition && <Marker {...markerPosition}/>}
    </GoogleMapReact></div>
}
