import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Avatar, Card, CardContent, Grid } from '@material-ui/core';
import { ApiService } from 'services/ApiService';
import { CategoriaServico } from 'models/PrestadorDeServico';
import { IJsonFromField } from 'components/InputFormulario/types';
import { Formulario } from 'components/Formulario';
import { BtnSelectAndCropImage } from 'components/CropImage';


const jsonForm: IJsonFromField[] = [
    {
        name: 'nome',
        label: 'Nome',
        type: 'text',
        validators: {
            required: {
                value: true,
                message: 'Nome é obrigatório'
            }
        }
    },
    {
        name: 'descricao',
        label: 'Descrição',
        type: 'text-area',
        validators: {
            required: {
                value: true,
                message: 'Descrição é obrigatório'
            }
        }
    },
    {
        type: 'multi-select',
        name: 'categorias',
        label: 'Categorias',
        choices: () => {
            return ApiService.getAll('/categorias-servicos-formulario').then((e: CategoriaServico[]) => {
                return e.map((x) => {
                    return {
                        label: String(x.nome),
                        value: x.id
                    };
                });
            });
        }
    },
    {
        name: 'email',
        label: 'Email',
        type: 'email'
    },
    {
        name: 'telefone',
        label: 'Telefone',
        type: 'tel'
    },
    {
        name: 'whatsapp',
        label: 'Whatsapp',
        type: 'tel'
    },
    {
        name: 'site',
        label: 'Site',
        type: 'text',
        helpText:
            'O aplicativo Catarina é uma plataforma online que permite que empresas e profissionais\n' +
            '                                    divulguem seu trabalho aos condôminos. Por este formulário concordo que o app Catarina e\n' +
            '                                    seus desenvolvedores não possuem qualquer relação trabalhista e não fazem recomendação\n' +
            '                                    dos serviços prestados. Estou ciente de que meus dados podem ser removidos a qualquer\n' +
            '                                    momento da plataforma sem aviso prévio.'
    }
];

export function FormularioPrestadoresPage(props: RouteComponentProps) {
    const [loading, setLoading] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [novaImagem, setNovaImagem] = useState<File>();

    const handleSubmit = (data: any, callbackErro: any) => {
        setLoading(true);
        let novaData: any = { ...data };
        if (novaImagem) {
            novaData = new FormData();
            novaData.set('foto', novaImagem, 'foto_principal.png');
            Object.keys(data).forEach((key) => {
                if (key === 'categorias') {
                    data.categorias.forEach((x: string) => {
                        novaData.append(key, x);
                    });
                } else {
                    novaData.append(key, data[key]);
                }
            });
        } else {
            delete novaData.foto;
        }
        ApiService.post('/formulario-prestadores-escarpas', novaData).then(
            () => {
                setLoading(false);
                setSucesso(true);
            },
            (e) => {
                if (e.status === 400 && callbackErro) {
                    callbackErro(e.data);
                }
                setLoading(false);
            }
        );
    };

    const handleSubmitLogo = async (logo: HTMLCanvasElement) => {
        setNovaImagem(
            new File(
                [
                    await new Promise<Blob>((resolve, reject) => {
                        logo.toBlob((b) => {
                            if (b) resolve(b);
                            else reject();
                        });
                    })
                ],
                'imagem_pricimal.png'
            )
        );
    };

    return (
        <div
            className="app-wrapper"
            style={{
                overflow: 'scroll'
            }}>
            <Grid container alignItems="center" justify="center">
                <Grid item md={8} sm={10} xs={12}>
                    <Card>
                        <CardContent>
                            {!sucesso && (
                                <div>
                                    <h2 className="text-dark">
                                        Catarina
                                        <br />
                                        <small>Formulário de Prestadores de Serviço - Escarpas</small>
                                    </h2>
                                    <br />
                                    <br />
                                    <hr />
                                    <div className="text-center mt-4">
                                        <p style={{ textAlign: 'left' }}>Logomarca</p>
                                        <BtnSelectAndCropImage
                                            onSave={(imageCrop) => {
                                                handleSubmitLogo(imageCrop);
                                            }}>
                                            <Avatar
                                                className="mx-auto"
                                                variant="rounded"
                                                style={{
                                                    width: '100px',
                                                    height: '100px'
                                                }}
                                                alt={'Logo Marca'}
                                                src={novaImagem ? URL.createObjectURL(novaImagem) : undefined}
                                            />
                                        </BtnSelectAndCropImage>
                                    </div>
                                    <Formulario
                                        name={'FormPrestadores'}
                                        fields={jsonForm}
                                        onSubmit={handleSubmit}
                                        loading={loading}
                                        textSubmitButton={'Enviar'}
                                    />
                                </div>
                            )}
                            {sucesso && (
                                <div>
                                    <h2 className="text-dark">
                                        Catarina
                                        <br />
                                        <small>Formulário de Prestadores de Serviço - Escarpas</small>
                                    </h2>
                                    <h5 className="text-success">
                                        Seu cadastro foi realizado com sucesso!
                                        <br />
                                        Seus dados serão analisados, aguarde aprovação!
                                    </h5>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
