import {IJsonFromField} from 'components/InputFormulario/types';

export const PERMISSOES_USUARIO: { [key: string]: string } = {
    '0': 'Cadastro de Usuários',
    '1': 'Empreendimentos',
    '2': 'Clientes',
    '3': 'Notícias',
    '4': 'Prestadores de Serviço',
    '5': 'Reservas'
}

export class Usuario {
    public id?: number;
    public ativo?: boolean;
    public celular?: string;
    public email?: string;
    public first_name?: string;
    public last_name?: string;
    public senha?: string;
    public confirmar_senha?: string;
    public superusuario?: boolean;
    public permissoes?: Array<number>;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            this.ativo = defaultValue.ativo;
            this.celular = defaultValue.celular;
            this.superusuario = defaultValue.superusuario;
            this.permissoes = defaultValue.permissoes;
            this.id = defaultValue.id;
            this.first_name = defaultValue.first_name;
            this.last_name = defaultValue.last_name;
            this.email = defaultValue.email;
        }
    }

    public get nome() {
        return `${this.first_name || ''} ${this.last_name || ''}`.trim()
    };

    public getPermissoesDisplay(): string {
        if (this.superusuario) {
            return 'superusuario';
        } else if (this.permissoes) {
            return this.permissoes.map(x => PERMISSOES_USUARIO[String(x)]).join(', ');
        } else {
            return 'Nenhuma permissão definida!'
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        const formulario: IJsonFromField[] = [
            {
                name: 'first_name',
                label: 'Primeiro Nome',
                type: 'text',
                valueDefault: this.first_name,
                validators: {
                    required: {
                        value: true,
                        message: 'Primeiro Nome é obrigatório'
                    }
                },
                onChange: data => this.first_name = data
            },
            {
                name: 'last_name',
                label: 'Último Nome',
                type: 'text',
                valueDefault: this.last_name,
                onChange: data => this.last_name = data
            },
            {
                name: 'email',
                label: 'Email',
                type: 'email',
                valueDefault: this.email,
                validators: {
                    required: {
                        value: true,
                        message: 'Email é obrigatório'
                    }
                },
                onChange: data => this.email = data
            },
            {
                name: 'celular',
                label: 'Celular',
                type: 'tel',
                valueDefault: this.celular,
                onChange: data => this.celular = data
            },
            {
                name: 'superusuario',
                label: 'É Super Usuário?',
                type: 'select',
                onChange: (data) => this.superusuario = data === 'true',
                valueDefault: this.superusuario ? {
                    label: 'Sim',
                    value: 'true'
                } : {
                    label: 'Não',
                    value: 'false'
                },
                choices: [{
                    label: 'Sim',
                    value: 'true'
                }, {
                    label: 'Não',
                    value: 'false'
                }]
            },
            {
                name: 'permissoes',
                label: 'Permissões',
                type: 'multi-select',
                valueDefault: this.permissoes?.map(x => {
                    return {
                        label: PERMISSOES_USUARIO[String(x)],
                        value: String(x)
                    }
                }),
                disabled: () => !!this.superusuario,
                validators: {
                    validate: (val) => {
                        if (!this.superusuario && (!val || val.length === 0)) {
                            return 'É necessário informar ao menos uma permissão!'
                        }
                    }
                },
                choices: Object.keys(PERMISSOES_USUARIO).map(key => {
                    return {
                        label: PERMISSOES_USUARIO[key],
                        value: key
                    }
                }),
                onChange: data => this.permissoes = data?.map((x: any) => Number(x))
            },
        ]
        if (!this.id) {
            formulario.push(...[
                {
                    name: 'senha',
                    label: 'Senha de acesso',
                    type: 'password',
                    onChange: data => this.senha = data
                },
                {
                    name: 'confirmar_senha',
                    label: 'Confirmar senha',
                    type: 'password',
                    onChange: data => this.confirmar_senha = data
                }
            ] as IJsonFromField[])
        }
        return formulario;
    }
}
