import {combineReducers, Reducer} from 'redux';
import {connectRouter, LocationChangeAction, RouterState} from 'connected-react-router'
import Settings from 'reducers/Settings';
import Session from './Session';


export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  session: Session,
});
