import React, {useEffect, useState} from 'react';
import {createMuiTheme, ThemeOptions} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import 'assets/vendors/style';
import {IntlProvider} from 'react-intl';
import indigoTheme from './themes/indigoTheme';
import AppLayout from 'containers/AppLayout';
import AppLocale from 'lngProvider';
import {RouteComponentProps} from 'react-router';
import {SessionState, SettingsState, AppRootState} from 'reducers';
import {AuthService} from 'services/AuthService';
import {userSignIn, userSignOut} from 'actions/Session';
import {Loader} from 'components/Loader';
import {ApiEventsType} from 'services/ApiService';
import {ApiEvent} from 'app-events';
import {NotificationManager} from 'react-notifications';
import RTL from 'util/RTL';

const Portal = (props: RouteComponentProps) => {
    const dispatch = useDispatch();
    const {locale} = useSelector<AppRootState, SettingsState>(({settings}) => settings);
    const {usuario} = useSelector<AppRootState, SessionState>(({session}) => session);
    const [loading, setLoading] = useState(true);
    const {match, location} = props;

    useEffect(() => {
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

        if (!usuario) {
            AuthService.obterSessao().then((usuario) => {
                dispatch(userSignIn(usuario));
                setLoading(false);
            }, () => {
                props.history.push('/login?goBack=' + location.pathname);
            })
        } else {
            setLoading(false);
        }
    }, [usuario]);

    const apiHandler = (error: any) => {
        if (error.status === 403) {
            dispatch(userSignOut());
            return props.history.push('/login?goBack=' + location.pathname);
        }
        if (error.status === 500) {
            return NotificationManager.error('Tente novamente mais tarde!')
        }
    }

    useEffect(() => {
        ApiEvent.addListener(ApiEventsType.HTTP_ERROR, apiHandler);
        return () => {
            ApiEvent.removeListener(ApiEventsType.HTTP_ERROR, apiHandler);
        }
    })

    const applyTheme = createMuiTheme(indigoTheme as ThemeOptions);
    if (location.pathname === match.url) {
        return (<Redirect to={`${match.url}/home`}/>);
    }

    if (loading) {
        return <Loader/>;
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
        <ThemeProvider theme={applyTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <RTL>
                        <div className="app-main">
                            <AppLayout/>
                        </div>
                    </RTL>
                </IntlProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};


export default Portal;
