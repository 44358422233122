import {
    USER_CHANGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
} from 'constants/ActionTypes';
import {SessionAction, SessionState} from 'reducers';

const INIT_STATE: SessionState = {};

export default (state = INIT_STATE, action: SessionAction): SessionState => {
    switch (action.type) {
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case USER_CHANGE: {
            return {
                ...state,
                usuario: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {}
        }
        default:
            return state;
    }
}
