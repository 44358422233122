import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootState } from 'reducers';
import { Usuario } from 'models/Usuario';
import { Alert, Card, CardBody, CardHeader, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Formulario } from 'components/Formulario';
import { userChange } from 'actions/Session';
import { UsuarioService } from 'services/UsuarioService';

export function PerfilPage(props: RouteComponentProps) {
    const usuario = useSelector<AppRootState, Usuario | undefined>(({ session }) => session.usuario);
    const [tab, setTab] = useState<'1' | '2'>('1');
    const [loading, setLoading] = useState(false);
    const [saveSucess, setSaveSuccess] = useState(false);
    const dispatch = useDispatch();
    const handleSubmitPerfil = (data: any, callbackErro?: any, form?: HTMLFormElement | null) => {
        setLoading(true);
        UsuarioService.atualizarPerfil({ ...usuario, ...data }).then(
            (user) => {
                dispatch(userChange(user));
                setLoading(false);
                setSaveSuccess(true);
            },
            (err) => {
                setSaveSuccess(false);
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data);
                }
                setLoading(false);
            }
        );
    };
    return (
        <div className="app-wrapper">
            <Card>
                <CardBody>
                    <CardTitle>Perfil</CardTitle>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={tab === '1' ? 'active' : ''}
                                onClick={() => {
                                    setTab('1');
                                    setSaveSuccess(false);
                                }}>
                                Dados do Usuário
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tab === '2' ? 'active' : ''}
                                onClick={() => {
                                    setTab('2');
                                    setSaveSuccess(false);
                                }}>
                                Senha de Acesso
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                            <div className="p-5">
                                {saveSucess && tab === '1' && (
                                    <Alert color="success">Dados atualizados com sucesso!</Alert>
                                )}
                                <Formulario
                                    loading={loading}
                                    name={'FormPerfil'}
                                    fields={[
                                        {
                                            name: 'first_name',
                                            type: 'text',
                                            label: 'Nome',
                                            validators: {
                                                required: {
                                                    value: true,
                                                    message: 'Nome é obrigatório'
                                                }
                                            },
                                            valueDefault: usuario?.first_name
                                        },
                                        {
                                            name: 'last_name',
                                            type: 'text',
                                            label: 'Sobrenome',
                                            validators: {
                                                required: {
                                                    value: true,
                                                    message: 'Sobrenome é obrigatório'
                                                }
                                            },
                                            valueDefault: usuario?.last_name
                                        },
                                        {
                                            name: 'email',
                                            type: 'email',
                                            label: 'Nome',
                                            validators: {
                                                required: {
                                                    value: true,
                                                    message: 'Nome é obrigatório'
                                                }
                                            },
                                            valueDefault: usuario?.email
                                        }
                                    ]}
                                    onSubmit={handleSubmitPerfil}
                                    textSubmitButton="Salvar"
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="p-5">
                                {saveSucess && tab === '2' && (
                                    <Alert color="success">Senha atualizada com sucesso!</Alert>
                                )}
                                <Formulario
                                    loading={loading}
                                    name={'FormSenha'}
                                    fields={[
                                        {
                                            name: 'senha',
                                            type: 'password',
                                            label: 'Nova Senha',
                                            validators: {
                                                required: {
                                                    value: true,
                                                    message: 'Nova Senha é obrigatório'
                                                }
                                            }
                                        },
                                        {
                                            name: 'confirmar_senha',
                                            type: 'password',
                                            label: 'Confirmar Senha',
                                            validators: {
                                                required: {
                                                    value: true,
                                                    message: 'Confirmação é obrigatório'
                                                }
                                            }
                                        }
                                    ]}
                                    onSubmit={handleSubmitPerfil}
                                    textSubmitButton="Salvar"
                                />
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </div>
    );
}
