import React, {PropsWithChildren, useRef, useState} from 'react'
import {Modal, ModalBody} from 'reactstrap';
import {Cropper} from 'react-cropper';
import 'cropperjs/dist/cropper.css';

let cropper: Cropper | null = null;

export function BtnSelectAndCropImage(props: PropsWithChildren<{
    onSave: (canvas: HTMLCanvasElement) => void,
    cropRatio?: number
}>) {
    const refInput = useRef<HTMLInputElement>(null);
    const [state, setState] = useState({
        photoFile: undefined as any,
        photoUrl: undefined as any,
        inputValue: undefined as any,
        showPopup: false
    });
    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(s => {
            return {
                ...s,
                photoFile: e.target.files ? e.target.files[0] : undefined
            }
        })
        const file = e.target.files ? e.target.files[0] : undefined
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setState(s => {
                return {
                    ...s,
                    photoFile: file,
                    photoUrl: fileReader.result,
                    showPopup: true
                }
            })
        }
        if (file) {
            fileReader.readAsDataURL(file)
        }
    }
    const save = () => {
        if (cropper) {
            props.onSave(cropper?.getCroppedCanvas())
            setState(s => {
                return {...s, showPopup: false}
            })
        }
    }
    return (
        <>
            <button className="btn btn-link" onClick={() => {
                refInput?.current?.click();
            }}>
                {props.children}
            </button>
            <input type='file' accept="image/jpeg,image/png,image/jpg" value={state.inputValue} className="d-none"
                   ref={refInput}
                   onChange={handleFile}/>
            <Modal isOpen={state.showPopup} size={'xl'} toggle={() => {
                setState(s => {
                    return {
                        ...s,
                        showPopup: !s.showPopup
                    }
                });
            }}>
                <ModalBody>
                    <Cropper
                        src={state.photoUrl}
                        style={{height: 400, width: '100%'}}
                        // Cropper.js options
                        initialAspectRatio={props.cropRatio || 1}
                        aspectRatio={props.cropRatio || 1}
                        guides={false}
                        onInitialized={c => {
                            cropper = c;
                        }}
                    />
                    <div className="d-flex w-100 mt-2">
                        <button className="btn btn-warning text-white" onClick={() => setState(s => {
                            return {...s, showPopup: false}
                        })}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary ml-auto" onClick={save}>
                            Recortar
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
