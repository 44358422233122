import React, {useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {COLLAPSED_DRAWER, FIXED_DRAWER} from 'constants/ActionTypes';
import MailNotification from '../../../../components/MailNotification';
import AppNotification from '../../../../components/AppNotification';
import CardHeader from 'components/dashboard/Common/CardHeader/index';
import {toggleCollapsedNav} from 'actions/Setting';
import IntlMessages from 'util/IntlMessages';
import {RouteComponentProps} from 'react-router';
import {AppRootState, SettingsState} from 'reducers';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';

const Index = (props:RouteComponentProps) => {

    const dispatch = useDispatch();
    const {drawerType, navCollapsed} = useSelector<AppRootState, SettingsState>(({settings}) => settings);
    const [mailNotification, setMailNotification] = useState(false);
    const [appNotification, setAppNotification] = useState(false);

    const onAppNotificationSelect = () => {
        setAppNotification(!appNotification)
    };

    const onMailNotificationSelect = () => {
        setMailNotification(!mailNotification)
    };

    const onToggleCollapsedNav = () => {
        const val = !navCollapsed;
        dispatch(toggleCollapsedNav(val));
    };

    // const onSwitchLanguage = (lang) => {
    //     dispatch(switchLanguage(lang))
    // };

    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

    return (
        <AppBar className="app-main-header">
            <Toolbar className="app-toolbar" disableGutters={false}>
                <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                            onClick={onToggleCollapsedNav}>
                    <span className="menu-icon"/>
                </IconButton>

                <Link className="app-logo mr-2 mt-3 mb-0 d-none d-sm-block btn btn-link" to="/">
                    {/*<img src={require('assets/images/logo.png')} alt="Jambo" title="Jambo"/>*/}
                    <h1 className="text-white">Catarina</h1>
                </Link>

                <div className="ellipse-shape"/>
            </Toolbar>
        </AppBar>
    );
};

export const Header = withRouter(Index);
