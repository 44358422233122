import {
    USER_CHANGE,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
} from 'constants/ActionTypes';
import {Usuario} from 'models/Usuario';

export const userSignIn = (usuario: Usuario) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: {usuario}
    };
};

export const userChange = (usuario: Usuario) => {
    return {
        type: USER_CHANGE,
        payload: usuario
    };
};
export const userSignOut = () => {
    return {
        type: SIGNOUT_USER_SUCCESS
    };
};
