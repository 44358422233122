import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Collapse
} from 'reactstrap';
import { Formulario } from 'components/Formulario';
import { ControleDePaginacao } from 'components/ControleDePaginacao';
import { Paginacao } from 'util/Paginacao';
import { ApiService } from 'services/ApiService';
import { Loader } from 'components/Loader';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CategoriaReserva, ItemDeReserva, ReservaDeItem, STATUS_RESERVA, TIPOS_RESERVA } from 'models/Reserva';
import { Moeda } from 'components/Moeda';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

export function ReservasPage(props: RouteComponentProps) {
    const [tab, setTab] = useState<'1' | '2' | '3'>('1');
    const [paginacaoReservas, setPaginacaoReservas] = useState<Paginacao<ReservaDeItem> | null>();
    const [paginacaoItensReserva, setPaginacaoItensReserva] = useState<Paginacao<ItemDeReserva> | null>();
    const [reservas, setReservas] = useState<ReservaDeItem[]>();
    const [itensReserva, setItensReserva] = useState<ItemDeReserva[]>();
    const [categorias, setCategorias] = useState<CategoriaReserva[]>();
    const [itemReservaForm, setItemReservaForm] = useState<ItemDeReserva>();
    const [categoriaForm, setCategoriaForm] = useState<CategoriaReserva>();
    const [loading, setLoading] = useState(false);
    const [saveSucess, setSaveSuccess] = useState(false);
    const [showFiltros, setShowFiltros] = useState(false);
    const [showModalItemReserva, setShowModalItemReserva] = useState(false);
    const [detalheReserva, setDetalheReserva] = useState<ReservaDeItem>();
    const [showModalCategoria, setShowModalCategoria] = useState(false);
    const [reservaRecusa, setReservaRecusa] = useState<ReservaDeItem>();
    const [loaderItem, setLoaderItem] = useState<{ [key: number]: boolean }>({});
    const [filtros, setFiltros] = useState({
        status: [],
        item: []
    });
    const handleSubmit = (data: any, callbackErro?: any, form?: HTMLFormElement | null) => {
        setLoading(true);
        if (itemReservaForm?.id) {
            ApiService.put('/itens-reservas', String(itemReservaForm.id), data).then(
                (pr) => {
                    setItensReserva((p) => {
                        const prests = [...(p || [])];
                        const idx = p?.findIndex((x) => x.id === pr.id);
                        if (idx !== undefined && idx > -1) {
                            prests[idx] = new ItemDeReserva(pr);
                        }
                        return prests;
                    });
                    setShowModalItemReserva(false);
                    setLoading(false);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        } else {
            ApiService.post('/itens-reservas', data).then(
                (pr) => {
                    setItensReserva((p) => {
                        return [new ItemDeReserva(pr), ...(p || [])];
                    });
                    setLoading(false);
                    setShowModalItemReserva(false);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        }
    };

    const handleSubmitCategoria = (data: any, callbackErro?: any, form?: HTMLFormElement | null) => {
        setLoading(true);
        if (categoriaForm?.id) {
            ApiService.put('/categorias-reservas', String(categoriaForm.id), { ...categoriaForm, ...data }).then(
                (pr) => {
                    setCategorias((p) => {
                        const prests = [...(p || [])];
                        const idx = p?.findIndex((x) => x.id === pr.id);
                        if (idx !== undefined && idx > -1) {
                            prests[idx] = new CategoriaReserva(pr);
                        }
                        return prests;
                    });
                    setLoading(false);
                    setShowModalCategoria(false);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        } else {
            ApiService.post('/categorias-reservas', { ...categoriaForm, ...data }).then(
                (pr) => {
                    setCategorias((p) => {
                        return [new CategoriaReserva(pr), ...(p || [])];
                    });
                    setLoading(false);
                    setShowModalCategoria(false);
                },
                (e) => {
                    if (e.status === 400 && callbackErro) {
                        callbackErro(e.data);
                    }
                    setLoading(false);
                }
            );
        }
    };

    useEffect(() => {
        if (paginacaoReservas === undefined && tab === '1') {
            (async () => {
                try {
                    const pg: Paginacao<ReservaDeItem> = new Paginacao<ReservaDeItem>(
                        '/reservas',
                        ReservaDeItem,
                        await ApiService.getAll('/reservas', filtros),
                        filtros
                    );
                    setPaginacaoReservas(pg);
                    setReservas(await pg.getItensProximaPagina());
                } catch {
                    setReservas([]);
                    setPaginacaoReservas(null);
                }
            })();
        }
        if (categorias === undefined && tab === '2') {
            (async () => {
                try {
                    const cats = await ApiService.getAll('/categorias-reservas');
                    setCategorias(cats.map((x: any) => new CategoriaReserva(x)));
                } catch {
                    setCategorias([]);
                }
            })();
        }
        if (paginacaoItensReserva === undefined) {
            (async () => {
                try {
                    const pg: Paginacao<ItemDeReserva> = new Paginacao<ItemDeReserva>(
                        '/itens-reservas',
                        ItemDeReserva,
                        await ApiService.getAll('/itens-reservas')
                    );
                    setPaginacaoItensReserva(pg);
                    setItensReserva(await pg.getItensProximaPagina());
                } catch {
                    setItensReserva([]);
                    setPaginacaoItensReserva(null);
                }
            })();
        }
    }, [tab, paginacaoReservas, paginacaoItensReserva]);

    const toggleAtivacaoItemReserva = (itemDeReserva: ItemDeReserva) => {
        if (
            window.confirm(
                itemDeReserva.ativo ? 'Dejesa realmente inativar esse item?' : 'Dejesa reamente ativar esse item?'
            )
        ) {
            setLoading(true);
            ApiService.put('/itens-reservas', String(itemDeReserva.id), {
                ...itemDeReserva,
                ativo: !itemDeReserva.ativo
            }).then(
                (x) => {
                    setItensReserva((a) => {
                        const as = [...(a || [])];
                        const idx = as.findIndex((aa) => aa.id === x.id);
                        if (idx !== undefined && idx > -1) {
                            as[idx] = new ItemDeReserva(x);
                        }
                        return as;
                    });
                    setLoading(false);
                },
                () => setLoading(false)
            );
        }
    };

    const alterarStatusReserva = (
        item: ReservaDeItem,
        acao: 'CONFIRMAR' | 'RECUSAR' | 'INICIAR' | 'FINALIZAR',
        motivo?: string
    ) => {
        if (acao === 'RECUSAR' && !motivo) {
            setReservaRecusa(item);
        } else {
            setReservaRecusa(undefined);
            if (window.confirm('Deseja realmente realizar essa operação?')) {
                setLoaderItem((x) => ({ ...x, [item.id || '']: true }));
                ApiService.put('/reservas', String(item.id), { acao, motivo }).then(
                    (res) => {
                        setReservas((rr) => {
                            const rrr = [...(rr || [])];
                            const idx = rrr.findIndex((i) => i.id === item.id);
                            if (idx >= 0) {
                                rrr[idx] = res;
                            }
                            return rrr;
                        });
                        setLoaderItem((x) => ({ ...x, [item.id || '']: false }));
                    },
                    () => setLoaderItem((x) => ({ ...x, [item.id || '']: false }))
                );
            }
        }
    };

    return (
        <div className="app-wrapper">
            <Card>
                <CardBody>
                    <CardTitle>Reservas</CardTitle>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={tab === '1' ? 'active' : ''}
                                onClick={() => {
                                    setTab('1');
                                    setSaveSuccess(false);
                                }}>
                                Reservas Realizadas
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tab === '2' ? 'active' : ''}
                                onClick={() => {
                                    setTab('2');
                                    setSaveSuccess(false);
                                }}>
                                Categorias
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tab === '3' ? 'active' : ''}
                                onClick={() => {
                                    setTab('3');
                                    setSaveSuccess(false);
                                }}>
                                Itens para Reserva
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                            <div className="p-5">
                                <h3 className={'text-right'}>
                                    <button
                                        className={'btn btn-outline-dark'}
                                        onClick={() => setShowFiltros((x) => !x)}>
                                        Filtros:{' '}
                                        {showFiltros ? (
                                            <ArrowDropUp fontSize={'small'} />
                                        ) : (
                                            <ArrowDropDown fontSize={'small'} />
                                        )}
                                    </button>
                                </h3>
                                <Collapse isOpen={showFiltros}>
                                    <Formulario
                                        name={'Filtro'}
                                        fields={[
                                            {
                                                type: 'multi-select',
                                                name: 'status',
                                                label: 'Status',
                                                choices: Object.keys(STATUS_RESERVA).map((x: any) => {
                                                    return {
                                                        value: x,
                                                        label: STATUS_RESERVA[x]
                                                    };
                                                }),
                                                valueDefault: filtros.status.map((x: any) => {
                                                    return {
                                                        value: x,
                                                        label: STATUS_RESERVA[x]
                                                    };
                                                })
                                            },
                                            {
                                                type: 'multi-select',
                                                name: 'item',
                                                label: 'Items',
                                                choices: async () => {
                                                    return ApiService.getAll('/itens-reservas', {
                                                        pagination: false
                                                    }).then((x) => {
                                                        return x.map((res: any) => {
                                                            return {
                                                                value: res.id,
                                                                label: res.nome
                                                            };
                                                        });
                                                    });
                                                },
                                                valueDefault: filtros.status.map((x: any) => {
                                                    return {
                                                        value: x,
                                                        label: STATUS_RESERVA[x]
                                                    };
                                                })
                                            }
                                        ]}
                                        onSubmit={(data) => {
                                            console.log(data);
                                            setFiltros(data);
                                            setPaginacaoReservas(undefined);
                                        }}
                                        textSubmitButton={'Filtrar'}
                                    />
                                    <hr />
                                </Collapse>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>Cliente</th>
                                                <th>Empreendimento</th>
                                                <th>Valor</th>
                                                <th>Data Solicitada</th>
                                                <th>Status</th>
                                                <th>Data Cadastro</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reservas === undefined && (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            )}
                                            {reservas &&
                                                reservas.map((u) => {
                                                    const unidade = u.usuario_cliente?.cliente?.unidades?.find((x) => {
                                                        return x.empreendimento === u.empreendimento;
                                                    });
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{u.nome_item}</td>
                                                            <td>
                                                                {u.usuario_cliente?.cliente?.nome}
                                                                <br />
                                                                {unidade?.descricao}
                                                            </td>
                                                            <td>{u.empreendimento_detalhe?.nome}</td>
                                                            <td>
                                                                <Moeda value={u?.valor || 0} />
                                                            </td>
                                                            <td>
                                                                {u.data
                                                                    ? moment(u.data).format('DD/MM/YYYY HH:mm:ss')
                                                                    : ''}
                                                            </td>
                                                            <td>{u.status ? STATUS_RESERVA[u.status] : ''}</td>
                                                            <td>
                                                                {moment(u.data_cadastro).format('DD/MM/YYYY HH:mm:ss')}
                                                            </td>
                                                            <td>
                                                                <div className={'btn-group'}>
                                                                    {u.id && u.status === 'AGUARDANDO' && (
                                                                        <>
                                                                            <button
                                                                                onClick={() =>
                                                                                    alterarStatusReserva(u, 'CONFIRMAR')
                                                                                }
                                                                                className={'btn btn-primary'}
                                                                                disabled={loaderItem[u.id]}>
                                                                                Confirmar
                                                                            </button>
                                                                            <button
                                                                                onClick={() =>
                                                                                    alterarStatusReserva(u, 'RECUSAR')
                                                                                }
                                                                                disabled={loaderItem[u.id]}
                                                                                className={
                                                                                    'btn btn-warning text-white'
                                                                                }>
                                                                                Recusar
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                    {u.id && u.status === 'CONFIRMADA' && (
                                                                        <>
                                                                            <button
                                                                                disabled={loaderItem[u.id]}
                                                                                onClick={() =>
                                                                                    alterarStatusReserva(u, 'INICIAR')
                                                                                }
                                                                                className={'btn btn-info'}>
                                                                                INICIAR RESERVA
                                                                            </button>
                                                                            <button
                                                                                onClick={() =>
                                                                                    alterarStatusReserva(u, 'RECUSAR')
                                                                                }
                                                                                disabled={loaderItem[u.id]}
                                                                                className={
                                                                                    'btn btn-warning text-white'
                                                                                }>
                                                                                Recusar
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                    {u.id && u.status === 'EM_UTILIZACAO' && (
                                                                        <button
                                                                            onClick={() =>
                                                                                alterarStatusReserva(u, 'FINALIZAR')
                                                                            }
                                                                            disabled={loaderItem[u.id]}
                                                                            className={'btn btn-success'}>
                                                                            FINALIZAR RESERVA
                                                                        </button>
                                                                    )}
                                                                    <button
                                                                        className={'btn btn-light'}
                                                                        onClick={() => setDetalheReserva(u)}>
                                                                        Detalhes
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {reservas && reservas.length === 0 && (
                                                <tr>
                                                    <td colSpan={7}>Nenhuma reserva cadastrado</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="p-5">
                                {saveSucess && tab === '2' && (
                                    <Alert color="success">Dados atualizados com sucesso!</Alert>
                                )}
                                <p>
                                    <button
                                        className={'btn btn-primary'}
                                        onClick={() => {
                                            setCategoriaForm(new CategoriaReserva());
                                            setShowModalCategoria(true);
                                        }}>
                                        Nova Categoria
                                    </button>
                                </p>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Data de Cadastro</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categorias === undefined && (
                                                <tr>
                                                    <td colSpan={3}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            )}
                                            {categorias &&
                                                categorias.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{u.nome}</td>
                                                            <td>
                                                                {moment(u.data_cadastro).format('DD/MM/YYYY HH:mm:ss')}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-light"
                                                                    onClick={() => {
                                                                        setCategoriaForm(u);
                                                                        setShowModalCategoria(true);
                                                                    }}>
                                                                    ...
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {categorias && categorias.length === 0 && (
                                                <tr>
                                                    <td colSpan={3}>Nenhuma categoria cadastrada</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="p-5">
                                {saveSucess && tab === '2' && (
                                    <Alert color="success">Dados atualizads com sucesso!</Alert>
                                )}
                                <p>
                                    <button
                                        className={'btn btn-primary'}
                                        onClick={() => {
                                            setItemReservaForm(new ItemDeReserva());
                                            setShowModalItemReserva(true);
                                        }}>
                                        Novo item
                                    </button>
                                </p>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Empreendimento</th>
                                                <th>Nome</th>
                                                <th>Descrição</th>
                                                <th>Categorias</th>
                                                <th>Tipo</th>
                                                <th>Quantidade</th>
                                                <th>Valor</th>
                                                <th>Ativo?</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itensReserva === undefined && (
                                                <tr>
                                                    <td colSpan={9}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            )}
                                            {itensReserva &&
                                                itensReserva.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{u?.empreendimento_detalhe?.nome}</td>
                                                            <td>{u?.nome}</td>
                                                            <td>{u?.descricao}</td>
                                                            <td>
                                                                {u.categorias_detalhes?.map((x) => x.nome).join(', ')}
                                                            </td>
                                                            <td>{u.tipo ? TIPOS_RESERVA[u.tipo] : ''}</td>
                                                            <td>{u?.quantidade}</td>
                                                            <td>
                                                                <Moeda value={u?.valor || 0} />
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className={
                                                                        'btn btn-' + (u.ativo ? 'danger' : 'success')
                                                                    }
                                                                    onClick={() => {
                                                                        toggleAtivacaoItemReserva(u);
                                                                    }}>
                                                                    {u.ativo ? 'Desativar Item' : 'Ativar Item'}
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-light"
                                                                    onClick={() => {
                                                                        setItemReservaForm(u);
                                                                        setShowModalItemReserva(true);
                                                                    }}>
                                                                    ...
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            {itensReserva && itensReserva.length === 0 && (
                                                <tr>
                                                    <td colSpan={9}>Nenhuma avaliação cadastrada</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
                <CardFooter>
                    {tab === '1' && paginacaoReservas && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoReservas.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setReservas(undefined);
                                paginacaoReservas.getItensDaPagina(pg).then((us) => {
                                    setReservas(us);
                                });
                            }}
                        />
                    )}
                    {tab === '3' && paginacaoItensReserva && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoItensReserva.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setItensReserva(undefined);
                                paginacaoItensReserva.getItensDaPagina(pg).then((us) => {
                                    setItensReserva(us);
                                });
                            }}
                        />
                    )}
                </CardFooter>
            </Card>
            <Modal isOpen={showModalItemReserva} toggle={() => setShowModalItemReserva((s) => !s)}>
                <ModalHeader toggle={() => setShowModalItemReserva((s) => !s)}>Item de Reserva</ModalHeader>
                <ModalBody>
                    {itemReservaForm && (
                        <Formulario
                            name="itemReservaForm"
                            fields={itemReservaForm.getFieldsFormulario()}
                            loading={loading}
                            onSubmit={handleSubmit}
                            textSubmitButton="Salvar"
                        />
                    )}
                </ModalBody>
            </Modal>
            <Modal isOpen={showModalCategoria} toggle={() => setShowModalCategoria((s) => !s)}>
                <ModalHeader toggle={() => setShowModalCategoria((s) => !s)}>Categoria de Serviço</ModalHeader>
                <ModalBody>
                    {categoriaForm && (
                        <Formulario
                            name="Perfil"
                            fields={categoriaForm.getFieldsFormulario()}
                            loading={loading}
                            onSubmit={handleSubmitCategoria}
                            textSubmitButton="Salvar">
                            {categoriaForm.id && (
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        if (window.confirm('Deseja realmente excluir essa categoria?')) {
                                            setLoading(true);
                                            ApiService.delete('/categorias-reservas', String(categoriaForm?.id)).then(
                                                () => {
                                                    setLoading(false);
                                                    setShowModalCategoria(false);
                                                    setCategorias((p) => {
                                                        const prests = [...(p || [])];
                                                        const idx = p?.findIndex((x) => x.id === categoriaForm?.id);
                                                        if (idx !== undefined && idx > -1) {
                                                            prests.splice(idx, 1);
                                                        }
                                                        return prests;
                                                    });
                                                },
                                                () => setLoading(false)
                                            );
                                        }
                                    }}
                                    disabled={loading}>
                                    {loading && <CircularProgress color={'inherit'} size={'1rem'} />}
                                    Excluir
                                </Button>
                            )}
                        </Formulario>
                    )}
                </ModalBody>
            </Modal>
            <Modal isOpen={!!reservaRecusa} toggle={() => setReservaRecusa(undefined)}>
                <ModalHeader toggle={() => setReservaRecusa(undefined)}>Recusa de reserva</ModalHeader>
                <ModalBody>
                    <Formulario
                        name={'RecuaReserva'}
                        fields={[
                            {
                                name: 'motivo',
                                type: 'text-area',
                                label: 'Motívo da recusa',
                                helpText:
                                    'Informe aqui o motívo da recusa. Essa informação será fornecida ao solicitante da reserva',
                                validators: {
                                    required: {
                                        value: true,
                                        message: 'Informe um motívo'
                                    }
                                }
                            }
                        ]}
                        onSubmit={(data) => {
                            if (reservaRecusa) {
                                alterarStatusReserva(reservaRecusa, 'RECUSAR', data.motivo);
                            }
                        }}
                        textSubmitButton={'Recusar Reserva'}
                    />
                </ModalBody>
            </Modal>
            <Modal isOpen={!!detalheReserva} toggle={() => setDetalheReserva(undefined)}>
                <ModalHeader toggle={() => setDetalheReserva(undefined)}>Detalhes da reserva</ModalHeader>
                <ModalBody>
                    {detalheReserva && (
                        <>
                            <h4>Movimentação:</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Observação</th>
                                            <th>De/Para</th>
                                            <th>Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Reserva realizada</td>
                                            <td>{STATUS_RESERVA['AGUARDANDO']}</td>
                                            <td>
                                                {moment(detalheReserva.data_cadastro).format('DD/MM/YYYY HH:mm:ss')}
                                            </td>
                                        </tr>
                                        {detalheReserva?.movimentacao?.map((x) => (
                                            <tr key={x.id}>
                                                <td>{x.observacao}</td>
                                                <td>{STATUS_RESERVA[x.status_atual]}</td>
                                                <td>{moment(x.data_cadastro).format('DD/MM/YYYY HH:mm:ss')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
}
