import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledCollapse
} from 'reactstrap';
import { Loader } from 'components/Loader';
import { ApiService } from 'services/ApiService';
import { ControleDePaginacao } from 'components/ControleDePaginacao';
import { Paginacao } from 'util/Paginacao';
import { Formulario } from 'components/Formulario';
import { Cliente } from 'models/Cliente';

export function ClientesPage(props: RouteComponentProps) {
    const [paginacaoClientes, setPaginacaoClientes] = useState<Paginacao<Cliente> | null>();
    const [clientes, setClientes] = useState<Cliente[]>();
    const [showFormCliente, setShowFormCliente] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (paginacaoClientes === undefined) {
            (async () => {
                try {
                    const pg: Paginacao<Cliente> = new Paginacao<Cliente>(
                        '/clientes',
                        Cliente,
                        await ApiService.getAll('/clientes')
                    );
                    setPaginacaoClientes(pg);
                    setClientes(await pg.getItensProximaPagina());
                } catch {
                    setClientes([]);
                    setPaginacaoClientes(null);
                }
            })();
        }
    });
    const atualizarClienteNaPagina = (cliente: Cliente) => {
        setClientes((u) => {
            const clientes1 = [...(u || [])];
            const i = clientes1.findIndex((x) => x.id === cliente.id);
            if (i > -1) {
                clientes1[i] = new Cliente(cliente);
            } else {
                clientes1.push(new Cliente(cliente));
            }
            return clientes1;
        });
    };
    const handleSubmitCliente = (data: any, callbackErro?: any) => {
        setLoading(true);
        const cpf = String(data.cpf).replace(/[^0-9]/g, '');
        ApiService.post('/clientes', { ...data, cpf: cpf }).then(
            (clienteAdd) => {
                atualizarClienteNaPagina(clienteAdd);
                setLoading(false);
                setShowFormCliente(false);
            },
            (err) => {
                if (err.status === 400 && err.data && callbackErro) {
                    callbackErro(err.data);
                }
                setLoading(false);
            }
        );
    };

    return (
        <div className="app-wrapper">
            <Card>
                <CardBody>
                    <CardTitle>Clientes</CardTitle>
                    <hr />
                    <div>
                        <p>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setShowFormCliente(true);
                                }}>
                                Novo Cliente
                            </button>
                        </p>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>CPF</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientes === undefined && (
                                        <tr>
                                            <td colSpan={5}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    )}
                                    {clientes &&
                                        clientes.map((u) => {
                                            return (
                                                <tr key={u.id}>
                                                    <td>{u.nome}</td>
                                                    <td>{u.getCpfFormatado()}</td>
                                                    <td>{u.email}</td>
                                                    <td>{u.getStatusDisplay()}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={() => {
                                                                props.history.push('/portal/cliente/' + String(u.id));
                                                            }}>
                                                            ...
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {clientes && clientes.length === 0 && (
                                        <tr>
                                            <td colSpan={5}>Nenhum cliente cadastrado</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    {paginacaoClientes && (
                        <ControleDePaginacao
                            totalPaginas={paginacaoClientes.getQuantidadeDePaginas()}
                            onPageChange={(pg) => {
                                setClientes(undefined);
                                paginacaoClientes.getItensDaPagina(pg).then((us) => {
                                    setClientes(us);
                                });
                            }}
                        />
                    )}
                </CardFooter>
            </Card>
            <Modal isOpen={showFormCliente} toggle={() => setShowFormCliente((s) => !s)}>
                <ModalHeader toggle={() => setShowFormCliente((s) => !s)}>Cadastro de Cliente</ModalHeader>
                <ModalBody>
                    <Formulario
                        name="Perfil"
                        fields={new Cliente().getFieldsFormulario()}
                        loading={loading}
                        onSubmit={handleSubmitCliente}
                        textSubmitButton="Salvar"
                    />
                </ModalBody>
            </Modal>
        </div>
    );
}
