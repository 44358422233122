import React, { useCallback, useEffect, useState } from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardTitle,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import { Empreendimento, FotoEmpreendimento, VideoEmpreendimento } from 'models/Empreemdimento';
import { ApiService } from 'services/ApiService';
import { Loader } from 'components/Loader';
import { Formulario } from 'components/Formulario';
import { BtnSelectAndCropImage } from 'components/CropImage';
import { Avatar } from '@material-ui/core';
import * as queryString from 'query-string';
import Dropzone, { useDropzone } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';

export function EmpreendimentoPage(props: any) {
    const [empreendimento, setEmpreendimento] = useState<Empreendimento | null>();
    const [loading, setLoading] = useState(false);
    const [loadingImagem, setLoadingImagem] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [showVideoForm, setShowVideoForm] = useState(false);
    const [videoForm, setVideoForm] = useState<VideoEmpreendimento>();
    const [fotoForm, setFotoForm] = useState<FotoEmpreendimento>();
    const [showFotoForm, setShowFotoForm] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        setLoading(true);
        const formPayload = new FormData();
        acceptedFiles.forEach((f: File) => {
            formPayload.append('foto', f);
        });
        formPayload.append('empreendimento', String(props.match.params.id));
        ApiService.post('/fotos-empreendimentos', formPayload).then(
            (f) => {
                setLoading(false);
                setSaveSuccess(true);
                setEmpreendimento((e) => {
                    const fotos = [...f, ...(e?.fotos || [])];
                    return e
                        ? new Empreendimento({
                              ...e,
                              fotos: fotos
                          })
                        : e;
                });
            },
            () => {
                setLoading(false);
                setSaveSuccess(false);
                window.alert('Por favor, arraste somente arquivos de imagens como JPG, JPEG e PNG.');
            }
        );
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    useEffect(() => {
        if (empreendimento === undefined) {
            (async () => {
                try {
                    setEmpreendimento(
                        new Empreendimento(await ApiService.get('/empreendimentos', props.match.params.id))
                    );
                } catch {
                    setEmpreendimento(null);
                }
            })();
        }
    });

    const handleSubmitLogo = (logo: HTMLCanvasElement) => {
        setLoadingImagem(true);
        (async () => {
            const dataSend = new FormData();
            dataSend.set(
                'foto_principal',
                new File(
                    [
                        await new Promise<Blob>((resolve, reject) => {
                            logo.toBlob((b) => {
                                if (b) resolve(b);
                                else reject();
                            });
                        })
                    ],
                    'logo.png'
                )
            );
            ApiService.put('/empreendimentos', String(empreendimento?.id), dataSend).then(
                (emp) => {
                    setEmpreendimento(new Empreendimento(emp));
                    setLoadingImagem(false);
                },
                (err) => {
                    setLoadingImagem(false);
                }
            );
        })();
    };

    const handleSubmit = async (data: any, callbackErro?: any) => {
        setLoading(true);
        if (empreendimento) {
            ApiService.put('/empreendimentos', String(empreendimento.id), data).then(
                (emp) => {
                    setEmpreendimento(new Empreendimento(emp));
                    setLoading(false);
                    setSaveSuccess(true);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                    setSaveSuccess(false);
                }
            );
        }
    };

    const handleSubmitFoto = async (data: any, callbackErro?: any) => {
        setLoading(true);
        if (fotoForm && fotoForm.id) {
            ApiService.put('/fotos-empreendimentos', String(fotoForm.id), {
                empreendimento: empreendimento?.id,
                ...data
            }).then(
                (foto: FotoEmpreendimento) => {
                    setEmpreendimento((e) => {
                        const fotos = [...(e?.fotos || [])];
                        const idx = fotos.findIndex((x) => x.id === foto.id);
                        if (idx > -1) {
                            fotos[idx] = foto;
                        }
                        return e
                            ? new Empreendimento({
                                  ...e,
                                  fotos: fotos
                              })
                            : e;
                    });
                    setLoading(false);
                    setShowFotoForm(false);
                    setSaveSuccess(true);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                    setSaveSuccess(false);
                }
            );
        }
    };

    const handleSubmitVideo = async (data: any, callbackErro?: any) => {
        setLoading(true);
        if (videoForm && videoForm.id) {
            ApiService.put('/videos-empreendimentos', String(videoForm.id), {
                empreendimento: empreendimento?.id,
                ...data
            }).then(
                (video: VideoEmpreendimento) => {
                    setEmpreendimento((e) => {
                        const videos = [...(e?.videos || [])];
                        const idx = videos.findIndex((x) => x.id === video.id);
                        if (idx > -1) {
                            videos[idx] = video;
                        }
                        return e
                            ? new Empreendimento({
                                  ...e,
                                  videos: videos
                              })
                            : e;
                    });
                    setLoading(false);
                    setShowVideoForm(false);
                    setSaveSuccess(true);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                    setSaveSuccess(false);
                }
            );
        } else {
            ApiService.post('/videos-empreendimentos', { empreendimento: empreendimento?.id, ...data }).then(
                (video: VideoEmpreendimento) => {
                    setEmpreendimento((e) => {
                        return e
                            ? new Empreendimento({
                                  ...e,
                                  videos: [...(e?.videos || []), video as any]
                              })
                            : e;
                    });
                    setShowVideoForm(false);
                    setLoading(false);
                    setSaveSuccess(true);
                },
                (err) => {
                    if (err.status === 400 && err.data && callbackErro) {
                        callbackErro(err.data);
                    }
                    setLoading(false);
                    setSaveSuccess(false);
                }
            );
        }
    };

    return (
        <div className="app-wrapper">
            <Card>
                {empreendimento === undefined && (
                    <CardBody>
                        <Loader />
                    </CardBody>
                )}
                {empreendimento && (
                    <CardBody>
                        <CardTitle>
                            <button className="btn btn-link text-dark" onClick={() => props.history.goBack()}>
                                <ArrowBack fontSize="large" />
                            </button>
                            {empreendimento.nome}
                        </CardTitle>
                        <hr />
                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '1' ? 'active' : ''}
                                        onClick={() => {
                                            setActiveTab('1');
                                            setSaveSuccess(false);
                                        }}>
                                        Informações Cadastrais
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '2' ? 'active' : ''}
                                        onClick={() => {
                                            setActiveTab('2');
                                            setSaveSuccess(false);
                                        }}>
                                        Sobre
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '3' ? 'active' : ''}
                                        onClick={() => {
                                            setActiveTab('3');
                                            setSaveSuccess(false);
                                        }}>
                                        Vídeos
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '4' ? 'active' : ''}
                                        onClick={() => {
                                            setActiveTab('4');
                                            setSaveSuccess(false);
                                        }}>
                                        Fotos
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Card>
                                        <CardBody>
                                            {saveSuccess && activeTab === '1' && (
                                                <Alert color="success">Dados atualizado com sucesso!</Alert>
                                            )}
                                            <h3>Foto Principal</h3>
                                            <div className="text-center mt-4">
                                                <BtnSelectAndCropImage
                                                    onSave={(imageCrop) => {
                                                        handleSubmitLogo(imageCrop);
                                                    }}>
                                                    {!loadingImagem && (
                                                        <Avatar
                                                            className="mx-auto"
                                                            variant="rounded"
                                                            style={{
                                                                width: '300px',
                                                                height: '300px'
                                                            }}
                                                            src={empreendimento?.foto_principal}
                                                        />
                                                    )}
                                                    {loadingImagem && <Loader />}
                                                </BtnSelectAndCropImage>
                                            </div>
                                            <Formulario
                                                name="Perfil"
                                                fields={empreendimento.getFieldsFormulario()}
                                                loading={loading}
                                                onSubmit={handleSubmit}
                                                textSubmitButton="Salvar">
                                                <p>
                                                    {empreendimento.ativo ? (
                                                        <Button
                                                            color={'danger'}
                                                            onClick={() => {
                                                                handleSubmit({ ativo: false });
                                                            }}
                                                            disabled={loading}>
                                                            {loading ? (
                                                                <CircularProgress color={'inherit'} size={'1rem'} />
                                                            ) : (
                                                                'Desativar Empreendimento'
                                                            )}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color={'success'}
                                                            onClick={() => {
                                                                handleSubmit({ ativo: true });
                                                            }}
                                                            disabled={loading}>
                                                            {loading ? (
                                                                <CircularProgress color={'inherit'} size={'1rem'} />
                                                            ) : (
                                                                'Ativar Empreendimento'
                                                            )}
                                                        </Button>
                                                    )}
                                                </p>
                                            </Formulario>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Card>
                                        <CardBody>
                                            {saveSuccess && activeTab === '2' && (
                                                <Alert color="success">Dados atualizado com sucesso!</Alert>
                                            )}
                                            <Formulario
                                                name="Sobre"
                                                fields={empreendimento.getFieldFormularioSobre()}
                                                loading={loading}
                                                onSubmit={(d, ec) => {
                                                    handleSubmit({ sobre: { ...d } }, ec);
                                                }}
                                                textSubmitButton="Salvar"
                                            />
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Card>
                                        <CardBody>
                                            {saveSuccess && activeTab === '3' && (
                                                <Alert color="success">Dados atualizado com sucesso!</Alert>
                                            )}
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setVideoForm({});
                                                    setShowVideoForm(true);
                                                }}>
                                                Novo vídeo
                                            </button>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Vídeo</th>
                                                            <th>Legenda</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {empreendimento.videos?.map((x) => {
                                                            return (
                                                                <tr key={x.id}>
                                                                    <td>
                                                                        <a
                                                                            href={x.video}
                                                                            target="_blank"
                                                                            className="btn btn-link"
                                                                            rel="noreferrer">
                                                                            <Avatar
                                                                                className="mx-auto"
                                                                                variant="rounded"
                                                                                style={{
                                                                                    width: '100px',
                                                                                    height: '100px'
                                                                                }}
                                                                                src={`http://img.youtube.com/vi/${
                                                                                    queryString.parseUrl(
                                                                                        String(x.video)
                                                                                    ).query['v']
                                                                                }/2.jpg`}
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                    <td>{x.legenda}</td>
                                                                    <td>
                                                                        <ButtonToolbar>
                                                                            <ButtonGroup>
                                                                                <Button
                                                                                    color={'primary'}
                                                                                    onClick={() => {
                                                                                        setVideoForm(x);
                                                                                        setShowVideoForm(true);
                                                                                    }}>
                                                                                    Editar
                                                                                </Button>
                                                                                <Button
                                                                                    color={'danger'}
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            window.confirm(
                                                                                                'Deseja realmente excluir esse vídeo?'
                                                                                            )
                                                                                        ) {
                                                                                            ApiService.delete(
                                                                                                '/videos-empreendimentos',
                                                                                                String(x.id)
                                                                                            ).then(() => {
                                                                                                setEmpreendimento(
                                                                                                    (e) => {
                                                                                                        return e
                                                                                                            ? new Empreendimento(
                                                                                                                  {
                                                                                                                      ...e,
                                                                                                                      videos: [
                                                                                                                          ...(e?.videos ||
                                                                                                                              [])
                                                                                                                      ].filter(
                                                                                                                          (
                                                                                                                              y
                                                                                                                          ) =>
                                                                                                                              y.id !==
                                                                                                                              x.id
                                                                                                                      )
                                                                                                                  }
                                                                                                              )
                                                                                                            : e;
                                                                                                    }
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }}>
                                                                                    Excluir
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </ButtonToolbar>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        {empreendimento.videos?.length === 0 && (
                                                            <tr>
                                                                <td colSpan={3}>Nenhum vídeo cadastrado</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <Modal
                                                    isOpen={showVideoForm}
                                                    toggle={() => setShowVideoForm((s) => !s)}>
                                                    <ModalHeader toggle={() => setShowVideoForm((s) => !s)}>
                                                        Vídeo para {empreendimento.nome}
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        {videoForm && (
                                                            <Formulario
                                                                name="Perfil"
                                                                fields={[
                                                                    {
                                                                        name: 'video',
                                                                        label: 'URL Vídeo no Youtube',
                                                                        type: 'text',
                                                                        validators: {
                                                                            required: {
                                                                                value: true,
                                                                                message: 'Informe uma URL'
                                                                            },
                                                                            validate: (value: any) => {
                                                                                if (
                                                                                    value.indexOf('youtube.com') ===
                                                                                        -1 ||
                                                                                    !queryString.parseUrl(value).query[
                                                                                        'v'
                                                                                    ]
                                                                                ) {
                                                                                    return 'Insira uma URL do Youtube válida, ex: https://www.youtube.com/watch?v=9No-FiEInLA';
                                                                                }
                                                                            }
                                                                        },
                                                                        valueDefault: videoForm?.video
                                                                    },
                                                                    {
                                                                        name: 'legenda',
                                                                        label: 'Legenda do Vídeo',
                                                                        type: 'text',
                                                                        validators: {
                                                                            required: {
                                                                                value: true,
                                                                                message: 'Informe uma Legenda'
                                                                            }
                                                                        },
                                                                        valueDefault: videoForm?.legenda
                                                                    }
                                                                ]}
                                                                loading={loading}
                                                                onSubmit={handleSubmitVideo}
                                                                textSubmitButton="Salvar"
                                                            />
                                                        )}
                                                    </ModalBody>
                                                </Modal>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Card>
                                        <CardBody>
                                            {saveSuccess && activeTab === '4' && (
                                                <Alert color="success">Dados atualizado com sucesso!</Alert>
                                            )}
                                            {!loading && (
                                                <div {...getRootProps()} className="dropZone">
                                                    <input {...getInputProps()} />
                                                    <p>ARRASTE SUAS FOTOS OU CLIQUE PARA SELECIONAR</p>
                                                </div>
                                            )}
                                            {loading && (
                                                <div className="p-5">
                                                    <Loader />
                                                </div>
                                            )}
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Foto</th>
                                                            <th>Legenda</th>
                                                            <th>Ação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {empreendimento.fotos?.map((x) => {
                                                            return (
                                                                <tr key={x.id}>
                                                                    <td>
                                                                        <a
                                                                            href={x.foto}
                                                                            target="_blank"
                                                                            className="btn btn-link"
                                                                            rel="noreferrer">
                                                                            <Avatar
                                                                                className="mx-auto"
                                                                                variant="rounded"
                                                                                style={{
                                                                                    width: '100px',
                                                                                    height: '100px'
                                                                                }}
                                                                                src={x.foto}
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                    <td>{x.legenda}</td>
                                                                    <td>
                                                                        <ButtonToolbar>
                                                                            <ButtonGroup>
                                                                                <Button
                                                                                    color={'primary'}
                                                                                    onClick={() => {
                                                                                        setFotoForm(x);
                                                                                        setShowFotoForm(true);
                                                                                    }}>
                                                                                    Editar
                                                                                </Button>
                                                                                <Button
                                                                                    color={'danger'}
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            window.confirm(
                                                                                                'Deseja realmente excluir essa foto?'
                                                                                            )
                                                                                        ) {
                                                                                            ApiService.delete(
                                                                                                '/fotos-empreendimentos',
                                                                                                String(x.id)
                                                                                            ).then(() => {
                                                                                                setEmpreendimento(
                                                                                                    (e) => {
                                                                                                        return e
                                                                                                            ? new Empreendimento(
                                                                                                                  {
                                                                                                                      ...e,
                                                                                                                      fotos: [
                                                                                                                          ...(e?.fotos ||
                                                                                                                              [])
                                                                                                                      ].filter(
                                                                                                                          (
                                                                                                                              y
                                                                                                                          ) =>
                                                                                                                              y.id !==
                                                                                                                              x.id
                                                                                                                      )
                                                                                                                  }
                                                                                                              )
                                                                                                            : e;
                                                                                                    }
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }}>
                                                                                    Excluir
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </ButtonToolbar>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        {empreendimento.fotos?.length === 0 && (
                                                            <tr>
                                                                <td colSpan={3}>Nenhuma foto cadastrada</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                <Modal isOpen={showFotoForm} toggle={() => setShowFotoForm((s) => !s)}>
                                                    <ModalHeader toggle={() => setShowFotoForm((s) => !s)}>
                                                        Foto para {empreendimento.nome}
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        {fotoForm && (
                                                            <Avatar
                                                                className="mx-auto"
                                                                variant="rounded"
                                                                style={{
                                                                    width: '100px',
                                                                    height: '100px'
                                                                }}
                                                                src={fotoForm.foto}
                                                            />
                                                        )}
                                                        {fotoForm && (
                                                            <Formulario
                                                                name="Perfil"
                                                                fields={[
                                                                    {
                                                                        name: 'legenda',
                                                                        label: 'Legenda da Foto',
                                                                        type: 'text',
                                                                        validators: {
                                                                            required: {
                                                                                value: true,
                                                                                message: 'Informe uma Legenda'
                                                                            }
                                                                        },
                                                                        valueDefault: fotoForm?.legenda
                                                                    }
                                                                ]}
                                                                loading={loading}
                                                                onSubmit={handleSubmitFoto}
                                                                textSubmitButton="Salvar"
                                                            />
                                                        )}
                                                    </ModalBody>
                                                </Modal>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </div>
                    </CardBody>
                )}
            </Card>
        </div>
    );
}
