import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { EmpreendimentosPage } from './EmpreendimentosPage';
import { HomePage } from './HomePage';
import { PerfilPage } from './PerfilPage';
import { RouteComponentProps } from 'react-router';
import { UsuariosPage } from 'app/routes/UsuariosPage';
import { EmpreendimentoPage } from 'app/routes/EmpreendimentoPage';
import { ClientesPage } from 'app/routes/ClientesPage';
import { ClientePage } from 'app/routes/ClientePage';
import { NoticiaPage } from 'app/routes/NoticiaPage';
import { PrestadoresPage } from 'app/routes/PrestadoresPage';
import { ReservasPage } from 'app/routes/ReservasPage';

const Routes = ({ match }: RouteComponentProps) => (
    <Switch>
        <Route
            path={`${match.url}/home`}
            render={(props) => {
                return <HomePage {...props} basePath={match} />;
            }}
        />
        <Route
            path={`${match.url}/empreendimentos`}
            render={(props) => {
                return <EmpreendimentosPage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/empreendimento/:id`}
            render={(props) => {
                return <EmpreendimentoPage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/perfil`}
            render={(props) => {
                return <PerfilPage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/usuarios`}
            render={(props) => {
                return <UsuariosPage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/clientes`}
            render={(props) => {
                return <ClientesPage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/cliente/:id`}
            render={(props) => {
                return <ClientePage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/noticias/`}
            render={(props) => {
                return <NoticiaPage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/prestadores/`}
            render={(props) => {
                return <PrestadoresPage {...props} />;
            }}
        />
        <Route
            path={`${match.url}/reservas/`}
            render={(props) => {
                return <ReservasPage {...props} />;
            }}
        />
    </Switch>
);

export default withRouter(Routes);
