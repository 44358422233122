import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import Portal from './containers';
import SignIn from './containers/SignIn';
import { NotificationContainer } from 'react-notifications';
import 'assets/vendors/style';
import { FormularioPrestadoresPage } from './app/routes/FormularioPrestadoresPage';

export const store = configureStore();

const MainApp = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <NotificationContainer />
            <Switch>
                <Route path="/portal" component={Portal} />
                <Route
                    path={`/formulario`}
                    render={(props) => {
                        return <FormularioPrestadoresPage {...props} />;
                    }}
                />
                <Route path="/" component={SignIn} />
            </Switch>
        </ConnectedRouter>
    </Provider>
);

export default MainApp;
