import React, {useState} from 'react';

export function ControleDePaginacao<T>(props: { paginaInicial?: number, totalPaginas: number, onPageChange: (pagina: number) => void }) {
    const [pagina, setPagina] = useState(props.paginaInicial || 1);
    const alterarPagina = (novaPagina: number) => {
        setPagina(novaPagina);
        props.onPageChange(novaPagina);
    }
    const temPaginaAnterior = pagina > 1;
    const temProximaPagina = pagina < props.totalPaginas;
    const range = [];
    while (range.length < props.totalPaginas) range.push(range.length + 1)
    return (
        <div className="text-center">
            <button className="btn btn-light" disabled={!temPaginaAnterior} onClick={() => alterarPagina(1)}>
                {'<<'}
            </button>
            <button className="btn btn-light" disabled={!temPaginaAnterior} onClick={() => alterarPagina(pagina - 1)}>
                {'<'}
            </button>
            {range.map(x => <button key={x} className="btn btn-light" disabled={x === pagina}
                                    onClick={() => alterarPagina(x)}>{x}</button>)}
            <button className="btn btn-light" disabled={!temProximaPagina} onClick={() => alterarPagina(pagina + 1)}>
                {'>'}
            </button>
            <button className="btn btn-light" disabled={!temProximaPagina}
                    onClick={() => alterarPagina(props.totalPaginas)}>
                {'>>'}
            </button>
        </div>
    )
}
