import enLang from './entries/en-US';
import ptLang from './entries/pt-BR';

interface AppLang {
    messages: { [key: string]: string },
    locale: string,
}

type AppLocaleType = { [key: string]: AppLang };

const AppLocale: AppLocaleType = {
    en: enLang as AppLang,
    pt: ptLang as AppLang
};

export default AppLocale;
