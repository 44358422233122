import React from 'react'
import NumberFormat from 'react-number-format';

export function Moeda(props: { value: number }) {
    return (
        <NumberFormat decimalScale={2}
                      fixedDecimalScale={true}
                      displayType='text'
                      thousandSeparator={'.'}
                      decimalSeparator={','} prefix={'R$ '}
                      value={Number(props.value)}/>
    )
}
