import React from 'react';

export function HomePage(props: any) {
    return (
        <div className="app-wrapper">
            <div className="row">
                <div className="col-12">
                    <div
                        className="jumbotron text-center d-flex flex-column align-items-center justify-content-center"
                        style={{
                            height: 'calc(100vh - 220px)'
                        }}>
                        <img
                            src={require('assets/images/avatar-web.png')}
                            style={{
                                width: '100%',
                                maxWidth: '280px'
                            }}
                        />
                        <br />
                        <h1 className="text-primary mt-4">
                            Portal Administrativo <br /> Catarina
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
}
