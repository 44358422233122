import {ApiService} from 'services/ApiService';
import {Usuario} from 'models/Usuario';

export class AuthService {
    private static URL_LOGIN = '/login';
    private static URL_SESSAO = '/sessao';

    static async realizarLogin(email: string, senha: string): Promise<Usuario> {
        return ApiService.post(AuthService.URL_LOGIN, {
            usuario: email,
            senha: senha
        }).then(user => {
            return new Usuario(user);
        });
    }

    static async obterSessao(): Promise<Usuario> {
        return await ApiService.get(AuthService.URL_SESSAO).then(data => {
            return new Usuario(data)
        });
    }

    static async realizarLogout() {
        return await ApiService.delete(AuthService.URL_SESSAO, '0');
    }
}
