import { IJsonFromField } from 'components/InputFormulario/types';
import { UsuarioCliente } from 'models/Cliente';
import { Empreendimento } from 'models/Empreemdimento';
import { ApiService } from 'services/ApiService';

export const TIPOS_RESERVA = {
    DIARIA: 'Por dia',
    POR_HORA: 'Por hora'
};

export const STATUS_RESERVA: { [key: string]: string } = {
    AGUARDANDO: 'Aguardando confirmação',
    CONFIRMADA: 'Reserva confirmada',
    EM_UTILIZACAO: 'Em utilização',
    RECUSADA: 'Recusada',
    CANCELADA: 'Cancelada',
    CONCLUIDA: 'Concluída'
};

export const DIAS_SEMANA = {
    1: 'Seg',
    2: 'Ter',
    3: 'Qua',
    4: 'Qui',
    5: 'Sex',
    6: 'Sáb',
    7: 'Dom'
};

export const HORAS_DIA = {
    '00:00:00': '00h00m',
    '01:00:00': '01h00m',
    '02:00:00': '02h00m',
    '03:00:00': '03h00m',
    '04:00:00': '04h00m',
    '05:00:00': '05h00m',
    '06:00:00': '06h00m',
    '07:00:00': '07h00m',
    '08:00:00': '08h00m',
    '09:00:00': '09h00m',
    '10:00:00': '10h00m',
    '11:00:00': '11h00m',
    '12:00:00': '12h00m',
    '13:00:00': '13h00m',
    '14:00:00': '14h00m',
    '15:00:00': '15h00m',
    '16:00:00': '16h00m',
    '17:00:00': '17h00m',
    '18:00:00': '18h00m',
    '19:00:00': '19h00m',
    '20:00:00': '20h00m',
    '21:00:00': '21h00m',
    '22:00:00': '22h00m',
    '23:00:00': '23h00m'
};

export class CategoriaReserva {
    public id?: number;
    public nome?: string;
    public data_cadastro?: string;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach((key) => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            });
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'nome',
                label: 'Nome da Categoria',
                type: 'text',
                valueDefault: this.nome,
                validators: {
                    required: {
                        value: true,
                        message: 'Nome é obrigatório'
                    }
                }
            }
        ];
    }
}

export class ItemDeReserva {
    public id?: number;
    public categorias?: number[];
    public categorias_detalhes?: CategoriaReserva[];
    public empreendimento?: number;
    public empreendimento_detalhe?: Empreendimento;
    public nome?: string;
    public descricao?: string;
    public tipo?: 'DIARIA' | 'POR_HORA';
    public quantidade?: number;
    public valor?: number;
    public ativo?: boolean;
    public data_cadastro?: string;
    public hora_inicio?: string;
    public hora_fim?: string;
    public dias?: (1 | 2 | 3 | 4 | 5 | 6 | 7)[];

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach((key) => {
                const obj: any = this;
                obj[key] = defaultValue[key];
            });
        }
    }

    public getFieldsFormulario(): IJsonFromField[] {
        return [
            {
                name: 'nome',
                label: 'Nome do item',
                type: 'text',
                valueDefault: this.nome,
                validators: {
                    required: {
                        value: true,
                        message: 'Nome é obrigatório'
                    }
                },
                onChange: (data) => (this.nome = data)
            },
            {
                name: 'descricao',
                label: 'Descrição',
                type: 'text',
                valueDefault: this.descricao,
                validators: {
                    required: {
                        value: true,
                        message: 'Descrição é obrigatório'
                    }
                },
                onChange: (data) => (this.descricao = data)
            },
            {
                valueDefault: this.tipo
                    ? {
                          value: this.tipo,
                          label: TIPOS_RESERVA[this.tipo]
                      }
                    : undefined,
                name: 'tipo',
                label: 'Tipo de reserva',
                type: 'select',
                validators: {
                    required: {
                        value: true,
                        message: 'Tipo é obrigatório'
                    }
                },
                choices: Object.keys(TIPOS_RESERVA).map((x) => {
                    return {
                        value: x,
                        label: (TIPOS_RESERVA as any)[x]
                    };
                }),
                onChange: (data) => (this.tipo = data)
            },
            {
                valueDefault: this.dias
                    ? this.dias.map((x) => ({
                          value: String(x),
                          label: DIAS_SEMANA[x]
                      }))
                    : undefined,
                name: 'dias',
                label: 'Dias que aceita reserva',
                type: 'multi-select',
                validators: {
                    required: {
                        value: true,
                        message: 'Informe ao menos um dia'
                    }
                },
                choices: Object.keys(DIAS_SEMANA).map((x) => {
                    return {
                        value: String(x),
                        label: (DIAS_SEMANA as any)[Number(x)]
                    };
                }),
                onChange: (data) => (this.dias = (data || []).map((y: any) => Number(y)))
            },
            {
                valueDefault: this.hora_inicio
                    ? {
                          value: this.hora_inicio,
                          label: (HORAS_DIA as any)[this.hora_inicio]
                      }
                    : undefined,
                name: 'hora_inicio',
                label: 'Hora de início',
                helpText: 'Hora do dia que começa a aceitar reservas',
                type: 'select',
                validators: {
                    required: {
                        value: true,
                        message: 'Hora de início é obrigatório'
                    }
                },
                choices: Object.keys(HORAS_DIA).map((x) => {
                    return {
                        value: x,
                        label: (HORAS_DIA as any)[x]
                    };
                }),
                onChange: (data) => (this.hora_inicio = data)
            },
            {
                valueDefault: this.hora_fim
                    ? {
                          value: this.hora_fim,
                          label: (HORAS_DIA as any)[this.hora_fim]
                      }
                    : undefined,
                name: 'hora_fim',
                label: 'Hora de fim',
                helpText: 'Hora do dia que finaliza as reservas',
                type: 'select',
                validators: {
                    required: {
                        value: true,
                        message: 'Hora de fim é obrigatório'
                    }
                },
                choices: Object.keys(HORAS_DIA).map((x) => {
                    return {
                        value: x,
                        label: (HORAS_DIA as any)[x]
                    };
                }),
                onChange: (data) => (this.hora_fim = data)
            },
            {
                name: 'quantidade',
                label: 'Quantidade',
                helpText: 'Quantidade de reservas simuntânes',
                type: 'text',
                valueDefault: this.quantidade,
                validators: {
                    required: {
                        value: true,
                        message: 'Quantidade é obrigatório'
                    }
                },
                onChange: (data) => (this.quantidade = data)
            },
            {
                name: 'valor',
                label: 'Valor',
                helpText: 'Qual valor sera cobrado pela reserva?',
                type: 'text',
                valueDefault: this.valor,
                validators: {
                    required: {
                        value: true,
                        message: 'Valor é obrigatório'
                    }
                },
                onChange: (data) => (this.valor = data)
            },
            {
                type: 'multi-select',
                name: 'categorias',
                label: 'Categorias',
                valueDefault: this.categorias_detalhes
                    ? this.categorias_detalhes.map((x) => {
                          return {
                              label: x.nome,
                              value: x.id
                          };
                      })
                    : undefined,
                choices: () => {
                    return ApiService.getAll('/categorias-reservas').then((e: CategoriaReserva[]) => {
                        return e.map((x) => {
                            return {
                                label: String(x.nome),
                                value: x.id
                            };
                        });
                    });
                },
                onChange: (data) => (this.categorias = data)
            },
            {
                type: 'select',
                name: 'empreendimento',
                label: 'Empreendimento',
                valueDefault: this.empreendimento_detalhe
                    ? {
                          value: this.empreendimento_detalhe.id,
                          label: this.empreendimento_detalhe.nome
                      }
                    : undefined,
                choices: () => {
                    return ApiService.getAll('/empreendimentos', { paginate: 'none' }).then((e: Empreendimento[]) => {
                        return e.map((x) => {
                            return {
                                label: String(x.nome),
                                value: x.id
                            };
                        });
                    });
                },
                onChange: (data) => (this.empreendimento = data)
            },
            {
                name: 'ativo',
                label: 'Item ativo?',
                type: 'select',
                onChange: (data) => (this.ativo = data === 'true'),
                valueDefault: this.ativo
                    ? {
                          label: 'Sim',
                          value: 'true'
                      }
                    : {
                          label: 'Não',
                          value: 'false'
                      },
                choices: [
                    {
                        label: 'Sim',
                        value: 'true'
                    },
                    {
                        label: 'Não',
                        value: 'false'
                    }
                ]
            }
        ];
    }
}

export class ReservaDeItem {
    public id?: number;
    public item?: number;
    public item_detalhe?: ItemDeReserva;
    public usuario_cliente?: UsuarioCliente;
    public data?: string;
    public valor?: number;
    public status?: 'AGUARDANDO' | 'CONFIRMADA' | 'EM_UTILIZACAO' | 'RECUSADA' | 'CONCLUIDA';
    public data_inicio?: string;
    public data_fim?: string;
    public motivo_recusa?: string;
    public nome_item?: string;
    public data_cadastro?: string;
    public empreendimento?: number;
    public movimentacao?: {
        id: number;
        status_anterior: 'AGUARDANDO' | 'CONFIRMADA' | 'EM_UTILIZACAO' | 'RECUSADA' | 'CONCLUIDA';
        status_atual: 'AGUARDANDO' | 'CONFIRMADA' | 'EM_UTILIZACAO' | 'RECUSADA' | 'CONCLUIDA';
        data_cadastro: string;
        observacao: string;
    }[];
    public empreendimento_detalhe?: Empreendimento;

    constructor(defaultValue?: any) {
        if (defaultValue) {
            Object.keys(defaultValue).forEach((key) => {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const obj: any = this;
                obj[key] = defaultValue[key];
            });
        }
    }

    getStatusReservaDisplay() {
        return this.status ? STATUS_RESERVA[this.status] : null;
    }
}
