import {ApiService} from 'services/ApiService';
import {Paginacao} from 'util/Paginacao';
import {Usuario} from 'models/Usuario';

export class UsuarioService {
    private static USUARIO_URL = '/usuarios'
    private static PERFIL_URL = '/perfil'

    public static async obterListaDeUsuarios(): Promise<Paginacao<Usuario>> {
        return await ApiService.getAll(UsuarioService.USUARIO_URL).then(data => new Paginacao(UsuarioService.USUARIO_URL, Usuario, data))
    }

    public static async atualizarPerfil(usuario: Usuario) {
        return await ApiService.put(UsuarioService.PERFIL_URL, String(usuario?.id), usuario).then(data => new Usuario(data))
    }

    public static async atualizarUsuario(usuario: Usuario) {
        return await ApiService.put(UsuarioService.USUARIO_URL, String(usuario?.id), usuario).then(data => new Usuario(data))
    }

    public static async bloquearUsuario(usuario: Usuario) {
        return await ApiService.patch(UsuarioService.USUARIO_URL, String(usuario?.id), {ativo: false}).then(data => new Usuario(data))
    }

    public static async ativarUsuario(usuario: Usuario) {
        return await ApiService.patch(UsuarioService.USUARIO_URL, String(usuario?.id), {ativo: true}).then(data => new Usuario(data))
    }

    public static async atualizarSenhaUsuario(id: number, senha: string, confirmar_senha: string) {
        return await ApiService.patch(UsuarioService.USUARIO_URL, String(id), {
            senha,
            confirmar_senha
        }).then(data => new Usuario(data))
    }

    public static async cadastrarUsuario(usuario: Usuario) {
        return await ApiService.post(UsuarioService.USUARIO_URL, usuario).then(data => new Usuario(data))
    }
}
