import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {RouteComponentProps} from 'react-router';
import {AppRootState, SettingsState} from 'reducers';
import AppLocale from 'lngProvider';
import {Usuario} from 'models/Usuario';
import {AuthService} from 'services/AuthService';
import {userSignIn} from 'actions/Session';
import {IJsonFromField} from 'components/InputFormulario/types';
import {Formulario} from 'components/Formulario';
import * as queryString from 'query-string';
import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme, ThemeOptions} from '@material-ui/core/styles';
import 'assets/vendors/style';
import indigoTheme from 'containers/themes/indigoTheme';


const formulario: IJsonFromField[] = [
    {
        name: 'usuario',
        type: 'email',
        label: 'Email',
        validators: {
            required: true
        }
    },
    {
        name: 'senha',
        type: 'password',
        label: 'Senha',
        validators: {
            required: true
        }
    }
];

const SignIn = (props: RouteComponentProps) => {

    const usuario = useSelector<AppRootState, Usuario | undefined>(({session}) => session.usuario);
    const {locale} = useSelector<AppRootState, SettingsState>(({settings}) => settings);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        const {goBack} = queryString.parse(props.location.search);
        if (usuario) {
            props.history.push(!goBack ? '/portal' : String(goBack));
        }
    }, [usuario]);

    const currentAppLocale = AppLocale[locale.locale];

    const realizarLogin = async (data: { usuario: string, senha: string }, errorCallback?: (erros: { [key: string]: string | string[] }) => void) => {
        setLoading(true);
        AuthService.realizarLogin(data.usuario, data.senha).then(usuarioLogado => {
            dispatch(userSignIn(usuarioLogado));
            setLoading(false);
        }, (e) => {
            setLoading(false);
            if (e.status === 400 && e.data && errorCallback)
                errorCallback(e.data)
        });
    }
    const applyTheme = createMuiTheme(indigoTheme as ThemeOptions);
    return (
        <ThemeProvider theme={applyTheme}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <div
                    className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="app-login-main-content">

                        <div
                            className="app-logo-content d-flex align-items-center justify-content-center flex-column text-center">
                            <img src={require('assets/images/avatar-web.png')} className='w-75'/>
                            <Link className="logo-lg mt-3" to="/">
                                <h2 className="text-white">Catarina<br/><small>Painel Administrativo</small></h2>
                            </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header mb-4">
                                <h1>Login</h1>
                            </div>

                            <div className="app-login-form">
                                <Formulario name={'Login'} fields={formulario} onSubmit={(data, errorCallback) => {
                                    realizarLogin(data, errorCallback);
                                }} textSubmitButton={'Entrar'} loading={loading}/>
                            </div>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </ThemeProvider>
    );
};


export default SignIn;
